import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
//component
import Login from "./Component/Login";
import Home from "./Component/Home";
import HospitalList from "./Component/HospitalList";
import Level from "./Component/Level";
import StockList from "./Component/StockList";
import LoginCustomer from "./Component/LoginCustomer";
import PurchaseOrder from "./Component/PurchaseOrder";
import User from "./Component/User";
import ConfirmOrder from "./Component/ConfirmOrder";
import Customer from "./Component/Customer";
import Delivery from "./Component/Delivery";
import History from "./Component/History";
import POHistory from "./Component/POHistory";
import AddPO from "./Component/AddPO";
import HospitalListViewDelivery from "./Component/HospitalListViewDelivery";
import ModalsDelivery from "./Component/ModalsDelivery";
import Product from "./Component/Product";
import Deliver from "./Component/DeliverList";
import DeliverCustomer from "./Component/DeliverListCustomer";

function App() {
  return (
    <div>
      <Router>
        <Routes>
          <Route exact path="/" element={<Login />} />
          <Route path="/home" element={<Home />} />
          <Route path="/hospitallist" element={<HospitalList />} />
          <Route path="/level" element={<Level />} />
          <Route path="/logincustomer" element={<LoginCustomer />} />
          <Route path="/user" element={<User />} />
          <Route path="/confirmOrder" element={<ConfirmOrder />} />
          <Route path="/customer" element={<Customer />} />
          <Route path="/delivery" element={<Delivery />} />
          <Route path="/purchaseorder" element={<PurchaseOrder />} />
          <Route path="/history" element={<History />} />
          <Route path="/pohistory" element={<POHistory />} />
          <Route path="/stockList" element={<StockList />} />
          <Route path="/hospitaldelivery" element={<HospitalListViewDelivery />} />
          <Route path="/addpo" element={<AddPO />} />
          <Route path="/adddelivery" element={<ModalsDelivery />} />
          <Route path="/product" element={<Product />} />
          <Route path="/deliverlist" element={<Deliver />} />
          <Route path="/deliverlistcustomer" element={<DeliverCustomer />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
