import React, { useState, useEffect } from "react";
import Menu from './Menu.js';
import Modal from "react-bootstrap/Modal";
import { Select } from "antd";
import { Col, Row, Form} from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useNavigate } from "react-router-dom";
import config from "../appsetting.json";
import TablePagination from "@mui/material/TablePagination";
import Swal from "sweetalert2";
// รูป
import logo from "./images/logo.svg";
import user_icon from "./images/user_icon.png";
// css
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "./styles/core.css";
import "./styles/icon-font.min.css";
import "./fonts/dropways.css";
import "./styles/style.css";
import "react-datepicker/dist/react-datepicker.css";

const { Option } = Select;

export default function StockList() {
  const [editstartDate, setEditStartDate] = useState(new Date(), "dd/mm/yyyy");
  const [show, setShow] = useState(false);
  const [showedit, setShowEdit] = useState(false);
  const [stockData, setStockData] = useState([]);
  const [stockDatacuslog, setStockDataCuslog] = useState([]);  const handleClose = () => setShow(false);
  const navigate = useNavigate();
  const UNAME = localStorage.getItem("UserName");
  const CusCode = localStorage.getItem("Cus_Code");

  const [editcreatedate, setEditCreateDate] = useState("");
  const [edititemnames, setEditItemNames] = useState("");
  const [editqty, setEditQty] = useState("");
  const [editsales, setEditSales] = useState("");
  const [stockkeyshow, setStockKeyShow] = useState("");
  const [item_name, setItemName] = useState("");
  const [qty, setQty] = useState("");
  const [sales, setSales] = useState("");
  const [salesOP, setSalesOP] = useState([]);
  const [filterresult, setFilterresult] = useState([]);
  const [filterresultcuslog, setFilterresultCuslog] = useState([]);
  const [serachinfo, setSearchinfo] = useState("");

  const [validated, setValidated] = useState(false);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("ASC");
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [visiblePurchaseOrder, setVisiblePurchaseOrder] = useState(false);
  const [visibleuseraccount, setVisibleUserAccount] = useState(false);
  const [visibleButtonAdd, setVisibleButtonAdd] = useState(false);
  const [visibletablestocklist, setVisibleTableStockList] = useState(false);
  const [visibleActiontable, setVisibleActiontable] = useState(false);
  const levelcode = localStorage.getItem("Level_Code");
  const CusLogCusCode = localStorage.getItem("Cus_Code");
  const checklevel = () => {
    if (levelcode == 100) {
      setVisiblePurchaseOrder(true);
      setVisibleUserAccount(true);
      setVisibleButtonAdd(true);
      setVisibleTableStockList(true);
      setVisibleActiontable(true);
    } else {
      setVisiblePurchaseOrder(false);
      setVisibleUserAccount(false);
      setVisibleButtonAdd(false);
      setVisibleTableStockList(false);
      setVisibleActiontable(false);
    }
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const clicklogOut = () => {
    localStorage.clear();
    navigate("/");
  };

  const getStockListCuslog = () => {
    fetch(config.ApiPathUrl_DevServer +"/getAllStockListCuslog/" + `${CusLogCusCode}`)
      .then((res) => res.json())
      .then((result) => {
        setStockDataCuslog(result);
      });
  };
  const getStockList = () => {
    fetch(config.ApiPathUrl_DevServer +"/getAllStockList")
      .then((res) => res.json())
      .then((result) => {
        setStockData(result.Data);
      });
  };
  const confirmAdd = () => {
    Swal.close();
    Swal.fire({
      icon: "success",
      title: "Success!",
      text: "You data has been added successfully.",
    });
  };

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      AddStock();
    }
    setValidated(true);
  };

  const changeSalesOP = (e) => {
    console.log(e);
    let d = document.getElementsByClassName("ant-select-selector");
    if (e !== "" && e !== undefined) {
      if (d[0].classList.contains("validation-select-invalid")) {
        d[0].classList.remove("validation-select-invalid");
        d[0].className += " validation-select-valid";
      }
      setSales(e);
    } else {
      if (d[0].classList.contains("validation-select-valid")) {
        d[0].classList.remove("validation-select-valid");
        d[0].className += " validation-select-invalid";
      }
      setSales("");
    }
  };
  const AddStock = () => {
    try {
      fetch(config.ApiPathUrl_DevServer + "/addStockList", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          Item_Name: item_name,
          Create_Date: editstartDate,
          Qty: qty,
          Sales: sales,
          Cus_Code: CusCode,
        }),
      })
        .then((res) => res.json())
        .then((result) => {
          console.log(result);
          if (result.Status == "Success") {
            // alert("Successfully Added");
            confirmAdd();
            setShow(false);
            window.location.reload();
          } else {
            alert(result.Message);
          }
        });
    } catch (error) {
      console.log(error);
      alert(error);
    }
  };

  //edit

  const confirmEdit = () => {
    Swal.close();
    Swal.fire({
      icon: "success",
      title: "Success!",
      text: "You data has been saved successfully.",
      // showConfirmButton: false,
      // timer: 1500
    });
  };

  const loadStockListOP = async () => {
    try {
      await fetch(config.ApiPathUrl_DevServer + "/getAllSales", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.json())
        .then((result) => {
          console.log(result);
          if (result.Status == "Success") {
            let option = [];
            result.Data.forEach((item) => {
              option.push(
                <Option key={item.Sales_Name}>{item.Sales_Name}</Option>
              );
            });
            setSalesOP(option);
          } else {
            alert(result.Message);
          }
        });
    } catch (error) {
      console.log(error);
      alert(error);
    }
  };
  const handlesearch = (event) => {
    const search = event.target.value;
    console.log(search);
    setSearchinfo(search);

    if (search !== "") {
      const filterdata = stockData.filter((item) => {
        return Object.values(item)
          .join("")
          .toLowerCase()
          .includes(search.toLowerCase());
      });
      setFilterresult(filterdata);
      const filterdatacuslog = stockDatacuslog.filter((item) => {
        return Object.values(item)
          .join("")
          .toLowerCase()
          .includes(search.toLowerCase());
      });
      setFilterresultCuslog(filterdatacuslog);
    } else {
      setFilterresult(stockData);
      setFilterresultCuslog(stockDatacuslog);
    }
  };  const editStock = async () => {
    // setLoading(true);
    try {
      await fetch(config.ApiPathUrl_DevServer + "/updateStockListByKey", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          Stock_Key: stockkeyshow,
          Create_Date: editcreatedate,
          Item_Name: edititemnames,
          Qty: editqty,
          Sales: editsales,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          if (data.Status == "Success") {
            confirmEdit();
            setShowEdit(false);
            window.location.reload();
          } else {
            alert(data.Message);
          }
          // setLoading(false);
        });
    } catch (error) {
      console.log(error);
      // setLoading(false);
    }
  };
  const sorting = (col) => {
    if (order === "ASC") {
      const sorted = [...stockData].sort((a, b) =>
        a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1
      );
      setStockData(sorted);
      setOrder("DSC");
    }
    if (order === "DSC") {
      const sorted = [...stockData].sort((a, b) =>
        a[col].toLowerCase() < b[col].toLowerCase() ? 1 : -1
      );
      setStockData(sorted);
      setOrder("ASC");
    }
  };

  const confirmDelete = () => {
    Swal.close();
    Swal.fire({
      icon: "success",
      title: "Success!",
      text: "You data has been deleted successfully.",
      // showConfirmButton: false,
      // timer: 1500
    });
  };
  const deleteStock = (deleteStocks) => {
    try {
      fetch(config.ApiPathUrl_DevServer + "/deleteStockListByKey", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          Stock_Key: deleteStocks,
        }),
      })
        .then((res) => res.json())
        .then((result) => {
          
          if (result.Status == "Success") {
            // alert("Successfully Added");
            confirmDelete();
            window.location.reload();
          } else {
            alert(result.Message);
          }
        });
    } catch (error) {
      console.log(error);
      alert(error);
    }
  };
  useEffect(() => {
    getStockList(); 
    loadStockListOP();
    getStockListCuslog();
  }, []);
  return (
    <div className="scrollbar-main sidebar-light body">
      <div className="header">
        <div className="header-left">
          <div className="menu-icon dw dw-menu pointer"></div>
        </div>
        <div className="header-right">
          <div className="user-info-dropdown">
            <div className="dropdown">
              <a
                className="dropdown-toggle"
                href="/#"
                role="button"
                data-bs-toggle="dropdown"
              >
                <span className="user-icon">
                  <img src={user_icon} alt="" />
                </span>
                <span className="user-name">{UNAME}</span>
              </a>
              <div className="dropdown-menu dropdown-menu-right dropdown-menu-icon-list">
                <a className="dropdown-item" onClick={clicklogOut}>
                  <i className="dw dw-logout"></i> Log Out
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="left-side-bar">
        <div className="brand-logo">
          <a href="/home">
            <img src={logo} alt="" className="dark-logo" width="180px" />
          </a>
          <div className="close-sidebar" data-bs-toggle="left-sidebar-close">
            <i className="ion-close-round"></i>
          </div>
        </div>
        <Menu/>
      </div>
      <div className="mobile-menu-overlay"></div>

      <div className="main-container">
        <div className="pd-ltr-20">
          <div className="card-box mb-30">
            <div className="card-body">
              <div className="clearfix">
                <div className="pull-left">
                  <h2 className="h2">Stocklist Management</h2>
                  {/* <h5 className="text-green">{cus_name}</h5> */}
                </div>
                
              {visibleButtonAdd && 
                <div className="pull-right">
                  <button
                    type="button"
                    className="btn btn-primary btn-sm"
                    // data-bs-toggle="modal"
                    // data-target="#add-data"
                    onClick={() => setShow(true)}
                  >
                    <i className="micon dw dw-syringe mr-2"></i>ADD PRODUCT
                  </button>
                </div>}
              </div>

              <div className="row mb-20 mt-3">
              <div className="col-sm-4">
                </div>
              <div className="col-sm-4">
              <input
              type="text"
              className="form-control"
              placeholder="Enter Keyword"
              onChange={(e) => {
                handlesearch(e);
              }}
            />
            <div className="col-sm-4">
              </div>
            </div>
                {/* <div className="col-sm-4">
                  <div className="form-group mb-2">
                    <label>Date Start - Date End</label>
                    <input
                      className="form-control form-control-sm datetimepicker-range"
                      type="text"
                    />
                    <DatePicker
                      selectsRange={true}
                      className="form-control form-control-sm datetimepicker-range"
                      startDate={startDate}
                      endDate={endDate}
                      onChange={(update) => {
                        setDateRange(update);
                      }}
                      isClearable={true}
                    />
                  </div>
                </div> */}

                {/* <Col md={4} className="mb-3">
                  <Form.Group>
                    <Form.Label>Sales Name</Form.Label>
                    <Select
                      style={{ width: "100%" }}
                      // class="custom-select custom-select-sm" 
                      showSearch
                      allowClear
                      placeholder="Select Sales"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    >
                      {salesOP}
                    </Select>
                  </Form.Group>
                </Col> */}
                {/* <div className="col-sm-4 d-flex align-items-center">
                  <button
                    type="button"
                    className="btn btn-sm btn-primary mt-sm-2 mt-1"
                  >
                    <i className="dw dw-search2 search-icon mr-2 "></i>SEARCH
                  </button>
                </div> */}
              </div>

              {visibletablestocklist && 
              <div className="table-responsive scrollbar-table">
                <table className="data-table table hover w-100 nowrap">
                  <thead>
                    <tr>
                      <th
                        className="text-center"
                        onClick={() => sorting("Stock_Key")}
                      >
                        Key
                      </th>
                      <th
                        className="text-center"
                        onClick={() => sorting("Create_Date")}
                      >
                        Create Date
                      </th>
                      <th
                        className="text-center"
                        onClick={() => sorting("Item_Name")}
                      >
                        Item Name
                      </th>
                      <th className="text-center">Qty</th>
                      <th
                        className="text-center"
                        onClick={() => sorting("Sales")}
                      >
                        Sales
                      </th>
                      <th className="datatable-nosort text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                  {serachinfo.length > 1
                  ? filterresult.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  ).map((filtercountry, index) => (
                      <tr key={index}>
                        <td className="text-center">{filtercountry.Stock_Key}</td>
                          <td className="text-center">{filtercountry.Create_Date}</td>
                          <td>{filtercountry.Item_Name}</td>
                          <td className="text-center">{filtercountry.Qty}</td>
                          <td>{filtercountry.Sales}</td>
                          <td className="text-center">
                            <div className="dropdown">
                              <a
                                className="btn btn-link font-24 p-0 line-height-1 no-arrow dropdown-toggle"
                                href="/#"
                                role="button"
                                data-bs-toggle="dropdown"
                              >
                                <i className="dw dw-more"></i>
                              </a>
                              <div className="dropdown-menu dropdown-menu-right dropdown-menu-icon-list">
                                <a className="dropdown-item" href="/pohistory">
                                  <i className="dw dw-eye"></i> View
                                </a>
                                <a
                                  className="dropdown-item"
                                  onClick={() => (
                                    setShowEdit(true),
                                    setStockKeyShow(filtercountry.Stock_Key)
                                  )}
                                >
                                  <i className="dw dw-edit2"></i> Edit
                                </a>
                                <a
                                  className="dropdown-item"
                                  onClick={() => deleteStock(filtercountry.Stock_Key)}
                                >
                                  <i className="dw dw-trash1"></i> Delete
                                </a>
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))
                      :
                    stockData
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((item, i) => (
                        <tr key={i}>
                          <td className="text-center">{item.Stock_Key}</td>
                          <td className="text-center">{item.Create_Date}</td>
                          <td>{item.Item_Name}</td>
                          <td className="text-center">{item.Qty}</td>
                          <td>{item.Sales}</td>
                          <td className="text-center">
                            <div className="dropdown">
                              <a
                                className="btn btn-link font-24 p-0 line-height-1 no-arrow dropdown-toggle"
                                href="/#"
                                role="button"
                                data-bs-toggle="dropdown"
                              >
                                <i className="dw dw-more"></i>
                              </a>
                              <div className="dropdown-menu dropdown-menu-right dropdown-menu-icon-list">
                                {/* <a className="dropdown-item" href="/pohistory">
                                  <i className="dw dw-eye"></i> View
                                </a> */}
                                <a
                                  className="dropdown-item"
                                  onClick={() => (
                                    setShowEdit(true),
                                    setStockKeyShow(item.Stock_Key)
                                  )}
                                >
                                  <i className="dw dw-edit2"></i> Edit
                                </a>
                                <a
                                  className="dropdown-item"
                                  onClick={() => deleteStock(item.Stock_Key)}
                                >
                                  <i className="dw dw-trash1"></i> Delete
                                </a>
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 15, 25, 50, 100]}
                    count={stockData.length}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </table>
              </div>
              }
              
              {!visibleActiontable && 
              <div className="table-responsive scrollbar-table">
                <table className="data-table table hover w-100 nowrap">
                  <thead>
                    <tr>
                      <th
                        className="text-center"
                        onClick={() => sorting("Stock_Key")}
                      >
                        Key
                      </th>
                      <th
                        className="text-center"
                        onClick={() => sorting("Create_Date")}
                      >
                        Create Date
                      </th>
                      <th
                        className="text-center"
                        onClick={() => sorting("Item_Name")}
                      >
                        Item Name
                      </th>
                      <th className="text-center">Qty</th>
                      <th
                        className="text-center"
                        onClick={() => sorting("Sales")}
                      >
                        Sales
                      </th>
              {visibleActiontable && 
                      <th className="datatable-nosort text-center">Action</th>}
                    </tr>
                  </thead>
                  <tbody>
                  {serachinfo.length > 1
                  ? filterresultcuslog.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  ).map((filtercountry, index) => (
                      <tr key={index}>
                        <td className="text-center">{filtercountry.Stock_Key}</td>
                          <td className="text-center">{filtercountry.Create_Date}</td>
                          <td>{filtercountry.Item_Name}</td>
                          <td className="text-center">{filtercountry.Qty}</td>
                          <td>{filtercountry.Sales}</td>
              {visibleActiontable && 
                          <td className="text-center">
                            <div className="dropdown">
                              <a
                                className="btn btn-link font-24 p-0 line-height-1 no-arrow dropdown-toggle"
                                href="/#"
                                role="button"
                                data-bs-toggle="dropdown"
                              >
                                <i className="dw dw-more"></i>
                              </a>
                              <div className="dropdown-menu dropdown-menu-right dropdown-menu-icon-list">
                                <a className="dropdown-item" href="/pohistory">
                                  <i className="dw dw-eye"></i> View
                                </a>
                                <a
                                  className="dropdown-item"
                                  onClick={() => (
                                    setShowEdit(true),
                                    setStockKeyShow(filtercountry.Stock_Key)
                                  )}
                                >
                                  <i className="dw dw-edit2"></i> Edit
                                </a>
                                <a
                                  className="dropdown-item"
                                  onClick={() => deleteStock(filtercountry.Stock_Key)}
                                >
                                  <i className="dw dw-trash1"></i> Delete
                                </a>
                              </div>
                            </div>
                          </td>}
                        </tr>
                      ))
                      :
                      stockDatacuslog
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((item, i) => (
                        <tr key={i}>
                          <td className="text-center">{item.Stock_Key}</td>
                          <td className="text-center">{item.Create_Date}</td>
                          <td>{item.Item_Name}</td>
                          <td className="text-center">{item.Qty}</td>
                          <td>{item.Sales}</td>
              {visibleActiontable && 
                          <td className="text-center">
                            <div className="dropdown">
                              <a
                                className="btn btn-link font-24 p-0 line-height-1 no-arrow dropdown-toggle"
                                href="/#"
                                role="button"
                                data-bs-toggle="dropdown"
                              >
                                <i className="dw dw-more"></i>
                              </a>
                              <div className="dropdown-menu dropdown-menu-right dropdown-menu-icon-list">
                                {/* <a className="dropdown-item" href="/pohistory">
                                  <i className="dw dw-eye"></i> View
                                </a> */}
                                <a
                                  className="dropdown-item"
                                  onClick={() => (
                                    setShowEdit(true),
                                    setStockKeyShow(item.Stock_Key)
                                  )}
                                >
                                  <i className="dw dw-edit2"></i> Edit
                                </a>
                                <a
                                  className="dropdown-item"
                                  onClick={() => deleteStock(item.Stock_Key)}
                                >
                                  <i className="dw dw-trash1"></i> Delete
                                </a>
                              </div>
                            </div>
                          </td>}
                        </tr>
                      ))}
                  </tbody>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 15, 25, 50, 100]}
                    count={stockDatacuslog.length}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </table>
              </div>}
            </div>
            

            {/* <!-- Add Modal --> */}
            <Modal
              show={show}
              onHide={() => setShow(false)}
              dialogClassName="modal-90w"
              aria-labelledby="Customer"
            >
              <Modal.Header closeButton>
                <Modal.Title id="Customer">Add Product</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div>
                  <main className="content">
                    <Form
                      noValidate
                      validated={validated}
                      onSubmit={handleSubmit}
                    >
                      <Row className="justify-content-center">
                        <div className="col-md-6 col-xl-12">
                          <Col md={12} className="mb-3">
                            <Form.Group>
                              <Form.Label>Date</Form.Label>
                              <Form.Label className="required-star">
                                *
                              </Form.Label>
                              <DatePicker 
                              className="form-control form-control-sm"
                              dateFormat="dd/MM/yyyy"
                              selected={editstartDate} onChange={(date) => setEditStartDate(date)} />
                              {/* <Form.Control
                                required
                                type="text"
                                placeholder="CreateDate"
                                // value={editstartDate}
                                onChange={(e) =>
                                  setEditStartDate(e.target.value)
                                }
                              /> */}
                            </Form.Group>
                          </Col>
                          <Col md={12} className="mb-3">
                            <Form.Group>
                              <Form.Label>Item Name</Form.Label>
                              <Form.Label className="required-star">
                                *
                              </Form.Label>
                              <Form.Control
                                required
                                type="text"
                                placeholder="Item"
                                value={item_name}
                                onChange={(e) => setItemName(e.target.value)}
                              />
                            </Form.Group>
                          </Col>
                          <Col md={12} className="mb-3">
                            <Form.Group>
                              <Form.Label>Qty</Form.Label>
                              <Form.Label className="required-star">
                                *
                              </Form.Label>
                              <Form.Control
                                required
                                type="text"
                                placeholder="Qty"
                                // value={cus_address}
                                onChange={(e) => setQty(e.target.value)}
                              />
                            </Form.Group>
                          </Col>
                          <Col md={12} className="mb-3">
                            <Form.Group>
                              <Form.Label>Sales Name</Form.Label>
                              <Select
                                style={{ width: "100%" }}
                                // className="custom-select custom-select-sm"
                                showSearch
                                allowClear
                                placeholder="Select Sales"
                                optionFilterProp="children"
                                onChange={changeSalesOP}
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                                }
                              >
                                {salesOP}
                              </Select>
                            </Form.Group>
                          </Col>
                        </div>
                      </Row>
                      <div className="mt-3 d-flex justify-content-center"></div>
                    </Form>
                  </main>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="button"
                  className="btn btn-outline-secondary btn-sm"
                  data-dismiss="modal"
                  onClick={handleClose}
                >
                  <i className="micon dw dw-cancel mr-2"></i>CANCEL
                </button>
                <button
                  type="button"
                  className="btn btn-primary btn-sm"
                  //   onClick="confirmAdd()"
                  onClick={AddStock}
                >
                  <i className="micon dw dw-add mr-2"></i>ADD
                </button>
              </Modal.Footer>
            </Modal>

            {/* <!-- Edit Modal --> */}
            <Modal
              show={showedit}
              onHide={() => setShowEdit(false)}
              dialogClassName="modal-90w"
              aria-labelledby="EditStockList"
            >
              <Modal.Header closeButton>
                <Modal.Title id="EditStockList">Edit Product</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div>
                  <main className="content">
                    <Form
                      noValidate
                      validated={validated}
                      onSubmit={handleSubmit}
                    >
                      <Row className="justify-content-center">
                        <div className="col-md-6 col-xl-12">
                          <Col md={12} className="mb-3">
                            <Form.Group>
                              <Form.Label>Stock_Key</Form.Label>
                              <Form.Label className="required-star">
                                *
                              </Form.Label>
                              <Form.Control
                                required
                                type="text"
                                placeholder="StockKey"
                                value={stockkeyshow}
                                readOnly
                              />
                            </Form.Group>
                          </Col>
                          <Col md={12} className="mb-3">
                            <Form.Group>
                              <Form.Label>Item Name</Form.Label>
                              <Form.Label className="required-star">
                                *
                              </Form.Label>
                              <Form.Control
                                required
                                type="text"
                                placeholder="Item"
                                // value={item_name}
                                onChange={(e) =>
                                  setEditItemNames(e.target.value)
                                }
                              />
                            </Form.Group>
                          </Col>
                          <Col md={12} className="mb-3">
                            <Form.Group>
                              <Form.Label>Qty</Form.Label>
                              <Form.Label className="required-star">
                                *
                              </Form.Label>
                              <Form.Control
                                required
                                type="text"
                                placeholder="Qty"
                                // value={cus_address}
                                onChange={(e) => setEditQty(e.target.value)}
                              />
                            </Form.Group>
                          </Col>
                          <Col md={12} className="mb-3">
                            <Form.Group>
                              <Form.Label>Sales Name</Form.Label>
                              <Select
                                style={{ width: "100%" }}
                                // className="custom-select custom-select-sm"
                                showSearch
                                allowClear
                                placeholder="Select Sales"
                                optionFilterProp="children"
                                onChange={changeSalesOP}
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                                }
                              >
                                {salesOP}
                              </Select>
                            </Form.Group>
                          </Col>
                        </div>
                      </Row>
                      <div className="mt-3 d-flex justify-content-center"></div>
                    </Form>
                  </main>
                </div>
              </Modal.Body>

              <Modal.Footer>
                <button
                  type="button"
                  className="btn btn-outline-secondary btn-sm"
                  data-dismiss="modal"
                  onClick={handleClose}
                >
                  <i className="micon dw dw-cancel mr-2"></i>CANCEL
                </button>
                <button
                  type="button"
                  className="btn btn-primary btn-sm"
                  onClick={editStock}
                >
                  <i className="micon dw dw-diskette1 mr-2"></i>SAVE
                </button>
              </Modal.Footer>
            </Modal>
          </div>
          <div className="footer-wrap pd-20 mb-20 card-box">
            &copy; <span id="copy-year"></span> Copyright All rights reserved.
          </div>
        </div>
      </div>
    </div>
  );
}
