import React, { useState, useEffect } from "react";
import Menu from './Menu.js';
import { useNavigate } from "react-router-dom";
import { Col, Row, Form, Card } from "react-bootstrap";
import { Select } from "antd";
import config from "../appsetting.json";
import Modal from "react-bootstrap/Modal";
import TablePagination from "@mui/material/TablePagination";
// รูป
import logo from "./images/logo.svg";
import user_icon from "./images/user_icon.png";
// css
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "./styles/core.css";
import "./styles/style.css";
import "./styles/icon-font.min.css";

//page

const { Option } = Select;

export default function Level() {
  const [lvlData, setLv] = useState([]);
  const Swal = require("sweetalert2");
  const handleClose = () => setShow(false);
  const handleEditClose = () => setShowEdit(false);
  const handleShow = () => setShow(true);
  const [show, setShow] = useState(false);
  const [showedit, setShowEdit] = useState(false);
  const navigate = useNavigate();
  const UNAME = localStorage.getItem("UserName");

  const [level_code, setLevelCode] = useState("");
  const [level_codeshow, setLevelCodeShow] = useState("");
  const [level_nameshow, setLevelNameShow] = useState("");

  const [level_codesearch, setLevelCodeSearch] = useState("");
  const [level_name, setLevelName] = useState("");
  const [level_namesearch, setLevelNameSearch] = useState("");


  //add
  const [addCode, setAddCode] = useState("");
  const [addName, setAddName] = useState("");

  //get edit
  const [EditGetKey, setEditGetKey] = useState("");
  const [EditGetCode, setEditGetCode] = useState("");
  const [EditGetName, setEditGetName] = useState("");

  //edit
  const [editkey, setEditkey] = useState("");
  const [editlevel_code, setEditLevelCode] = useState("");
  const [editlevel_name, setEditLevelName] = useState("");
  const [user_level, setUserlevel] = useState("");

  const tokenAu = localStorage.getItem("token");
  const levelcode = localStorage.getItem("Level_Code");

  const [validated, setValidated] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [order, setOrder] = useState("ASC"); 
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const sorting = (col) => {
    if (order === "ASC") {
      const sorted = [...lvlData].sort((a, b) =>
        a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1
      );
      setLv(sorted);
      setOrder("DSC");
    }
    if (order === "DSC") {
      const sorted = [...lvlData].sort((a, b) =>
        a[col].toLowerCase() < b[col].toLowerCase() ? 1 : -1
      );
      setLv(sorted);
      setOrder("ASC");
    }
  };

  useEffect(() => {
    GetAllLevel(); 
    //loadLevelByLevelCode();
  }, []);

  const clicklogOut = () => {
    localStorage.clear();
    navigate("/");
  };

  const GetAllLevel = () => {

    fetch(
      config.ApiPathUrl_DevServer + "/Levels",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          'Authorization': 'bearer '+tokenAu
        },
      }
      )
      .then((res) => res.json())
      .then((result) => {
        setLv(result);
      });
  };
  const confirmAdd = () => {
    Swal.close();
    Swal.fire({
      icon: "success",
      title: "Success!",
      text: "You data has been added successfully.",
      // showConfirmButton: false,
      // timer: 1500
    }).then(() => {
      window.location.reload();
    });
  };
  const confirmEdit = () => {
    Swal.close();
    Swal.fire({
      icon: "success",
      title: "Success!",
      text: "You data has been saved successfully.",
      // showConfirmButton: false,
      // timer: 1500
    }).then(() => {
      window.location.reload();
    });
  };

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false || levelcode === "100") {
      AddLevel();
    }
    setValidated(true);
  };
  const AddLevel = () => {
    try {
      fetch(config.ApiPathUrl_DevServer + "/Levels", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${tokenAu}`
        },
        body: JSON.stringify({
          id: 0,
          code: addCode,
          namE_LEVEL: addName,
        }),
      })
      .then(response => {
        console.log(response)
        if(response.status == 200){
          confirmAdd();
        }
      })
      .then(data => {
        console.log(data);
      })
      .catch(error => console.error(error));
    } catch (error) {
      console.log(error);
      alert(error);
    }
  };


  const loadLevelByLevelCode = async (id) => {
    try {
      await fetch(
       config.ApiPathUrl_DevServer + "/Levels/"+id,
       {
         method: "GET",
         headers: {
           "Content-Type": "application/json",
           'Authorization': 'bearer '+tokenAu
         },
      }).then((res) => res.json())
      .then((result) => {
        console.log(result);
        setEditGetKey(result.id);
        setEditGetCode(result.code);
        setEditGetName(result.namE_LEVEL);
      });
    } catch (error) {
      console.log(error);
      alert(error);
    }
  };


  const handleEditSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
    } else {
      editLevel();
    }
    setValidated(true);
  };


  const editLevel = async () => {
      await fetch(config.ApiPathUrl_DevServer + "/Levels", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          'Authorization': 'bearer '+tokenAu
        },
        body: JSON.stringify({
          id: EditGetKey,
          code: EditGetCode,
          namE_LEVEL: EditGetName,
        }),
      })
      .then(response => {
        console.log(response)
        if(response.status == 200){
          confirmEdit();
        }
      })
      .then(data => {
        console.log(data);
      })
      .catch(error => console.error(error));
  };

  const confirmDelete = () => {
    Swal.close();
    Swal.fire({
      icon: "success",
      title: "Success!",
      text: "You data has been deleted successfully.",
      // showConfirmButton: false,
      // timer: 1500
    }).then(() => {
      window.location.reload();
    });
  };
  
  const deleteLevel = (id) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${tokenAu}`);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "id": id
    });

    var requestOptions = {
      method: 'DELETE',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(config.ApiPathUrl_DevServer + "/Levels?id="+id, requestOptions)
      .then(response => response.text())
      .then(result => {
        confirmDelete();
      })
      .catch(error => console.log('error', error));
  };

  return (
    <div className="scrollbar-main sidebar-light body">
      <div className="header">
        <div className="header-left">
          <div className="menu-icon dw dw-menu pointer"></div>
        </div>
        <div className="header-right">
          <div className="user-info-dropdown">
            <div className="dropdown">
              <a
                className="dropdown-toggle"
                href="/#"
                role="button"
                data-bs-toggle="dropdown"
              >
                <span className="user-icon">
                  <img src={user_icon} alt="" />
                </span>
                <span className="user-name">{UNAME}</span>
              </a>
              <div className="dropdown-menu dropdown-menu-right dropdown-menu-icon-list">
                <a className="dropdown-item" onClick={clicklogOut}>
                  <i className="dw dw-logout"></i> Log Out
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="left-side-bar ">
        <div className="brand-logo">
          <a href="/homel">
            <img src={logo} alt="" className="dark-logo" width="180px" />
          </a>
          <div className="close-sidebar" data-bs-toggle="left-sidebar-close">
            <i className="ion-close-round"></i>
          </div>
        </div>
        <Menu/>
      </div>

      <div className="mobile-menu-overlay"></div>

      <div className="main-container">
        <div className="pd-ltr-20">
          <div className="card-box mb-30">
            <div className="card-body">
              <div className="clearfix">
                <div className="pull-left">
                  <h2 className="h2">Level Management</h2>
                </div>
                <div className="pull-right">
                  <button
                    type="button"
                    className="btn btn-primary btn-sm"
                    // data-bs-toggle="modal"
                    // data-target="#add-data"
                    onClick={() => setShow(true)}
                  >
                    <i className="micon dw dw-group mr-2"></i>ADD LEVEL
                  </button>
                </div>
              </div>

              <div className="table-responsive pd-20">
                <table className="data-table table hover w-100 nowrap">
                  <thead>
                    <tr>
                      <th
                        className="text-center"
                        onClick={() => sorting("Level_Code")}
                      >
                        Level Code
                      </th>
                      <th
                        className="text-center"
                        onClick={() => sorting("Level_Name")}
                      >
                        Level Name
                      </th>
                      <th className="datatable-nosort text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {lvlData
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((item, i) => (
                        <tr key={i}>
                          <td className="text-center">{item.code}</td>
                          <td className="text-center">{item.namE_LEVEL}</td>
                          <td className="text-center">
                            <div className="dropdown">
                              <a
                                className="btn btn-link font-24 p-0 line-height-1 no-arrow dropdown-toggle"
                                // href="/#"
                                role="button"
                                data-bs-toggle="dropdown"
                              >
                                <i className="dw dw-more"></i>
                              </a>
                              <div className="dropdown-menu dropdown-menu-right dropdown-menu-icon-list">
                                <a
                                  className="dropdown-item"
                                  onClick={() => (
                                    loadLevelByLevelCode(item.code),
                                    setShowEdit(true)
                                  )}
                                  // onClick={()=>setShowEdit(true)}
                                >
                                  <i className="dw dw-edit2"></i> Edit
                                </a>
                                <a
                                  className="dropdown-item"
                                  onClick={() => deleteLevel(item.id)}
                                >
                                  <i className="dw dw-trash1"></i> Delete
                                </a>
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))}
                    {/* <TablePagination
                      rowsPerPageOptions={[5, 10, 15, 20]}
                      count={lvlData.length}
                      page={page}
                      onPageChange={handleChangePage}
                      rowsPerPage={rowsPerPage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    /> */}
                  </tbody>
                </table>
              </div>
            </div>

            {/* <!-- Add Modal --> */}
            <Modal
              show={show}
              onHide={() => setShow(false)}
              dialogClassName="modal-90w"
              aria-labelledby="Level"
            >
              <Modal.Header closeButton>
                <Modal.Title id="Level">Add Level</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div>
                  <main className="content">
                    <Form
                      noValidate
                      validated={validated}
                      onSubmit={handleSubmit}
                    >
                      <Row className="justify-content-center">
                        <div className="col-md-6 col-xl-12">
                          <Col md={12} className="mb-3">
                            <Form.Group>
                              <Form.Label>Level_Code</Form.Label>
                              <Form.Label className="required-star">
                                *
                              </Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="UserCode"
                                //value={level_code}
                                onChange={(e) => setAddCode(e.target.value)}
                                required
                              />
                              {/* <span className="error" style={{color: "red"}}>{errorValidateUserName}</span> */}
                              {/* value={user_name}  onChange={(e) => handleChangeUserName(e)} */}
                            </Form.Group>
                          </Col>
                          <Col md={12} className="mb-3">
                            <Form.Group>
                              <Form.Label>Level_Name</Form.Label>
                              <Form.Label className="required-star">
                                *
                              </Form.Label>
                              <Form.Control
                                required
                                type="text"
                                placeholder="Level Name"
                                //value={level_name}
                                onChange={(e) => setAddName(e.target.value)}
                              />
                            </Form.Group>
                          </Col>
                        </div>
                      </Row>
                      <div className="mt-3 d-flex justify-content-center"></div>
                    </Form>
                  </main>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="button"
                  className="btn btn-outline-secondary btn-sm"
                  data-dismiss="modal"
                  onClick={handleClose}
                >
                  <i className="micon dw dw-cancel mr-2"></i>CANCEL
                </button>
                <button
                  type="submit"
                  className="btn btn-primary btn-sm"
                  // onClick={confirmAdd()}
                  onClick={AddLevel}
                >
                  <i className="micon dw dw-add mr-2"></i>ADD
                </button>
              </Modal.Footer>
            </Modal>

            {/* <!-- Edit Modal --> */}
            <Modal
              show={showedit}
              onHide={() => setShowEdit(false)}
              dialogClassName="modal-90w"
              aria-labelledby="Level"
            >
              <Modal.Header closeButton>
                <Modal.Title id="Level">Edit Level</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div>
                  <main className="content">
                    <Form
                      noValidate
                      validated={validated}
                      onSubmit={handleEditSubmit}
                    >
                      <Row className="justify-content-center">
                        <div className="col-md-6 col-xl-12">
                          <Col md={12} className="mb-3">
                            <Form.Group>
                              <Form.Label>Level Code</Form.Label>
                              <Form.Label className="required-star">
                                *
                              </Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="UserCode"
                                value={EditGetCode}
                                onChange={(e) =>
                                  setEditGetCode(e.target.value)
                                }
                                required
                              />
                              {/* <span className="error" style={{color: "red"}}>{errorValidateUserName}</span> */}
                              {/* value={user_name}  onChange={(e) => handleChangeUserName(e)} */}
                            </Form.Group>
                          </Col>
                          <Col md={12} className="mb-3">
                            <Form.Group>
                              <Form.Label>Level_Name</Form.Label>
                              <Form.Label className="required-star">
                                *
                              </Form.Label>
                              <Form.Control
                                required
                                type="text"
                                placeholder="Level Name"
                                value={EditGetName}
                                onChange={(e) => setEditGetName(e.target.value)}
                                readOnly = {false}
                              />
                            </Form.Group>
                          </Col>
                        </div>
                      </Row>
                      <div className="mt-3 d-flex justify-content-center"></div>
                    </Form>
                  </main>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="button"
                  className="btn btn-outline-secondary btn-sm"
                  data-dismiss="modal"
                  onClick={handleClose}
                >
                  <i className="micon dw dw-cancel mr-2"></i>CANCEL
                </button>
                <button
                  type="submit"
                  className="btn btn-primary btn-sm"
                  // onClick={confirmAdd()}
                  onClick={editLevel}
                >
                  <i className="micon dw dw-add mr-2"></i>Edit
                </button>
              </Modal.Footer>
            </Modal>
          </div>
          <div className="footer-wrap pd-20 mb-20 card-box">
            &copy; <span id="copy-year"></span> Copyright All rights reserved.
          </div>
        </div>
      </div>
    </div>
  );
}
