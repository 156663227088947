import React, { useState, useEffect } from "react";
import Menu from './Menu.js';
import { useNavigate } from "react-router-dom";
import config from "../appsetting.json";
import Modal from "react-bootstrap/Modal";
import { Select } from "antd";
import { Col, Row, Form, Card } from "react-bootstrap";
import Swal from "sweetalert2";
//photo
import logo from "./images/logo.svg";
import user_icon from "./images/user_icon.png";
// css
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "./styles/core.css";
import "./styles/style.css";
import "./styles/icon-font.min.css";

export default function Delivery() {
  const UNAME = localStorage.getItem("UserName");

  const [addpono, setAddPoNo] = useState([]);
  const [headpono, setHeadPONO] = useState([]);
  const [headdate, setHeadDate] = useState([]);
  const [headname, setHeadName] = useState([]);
  const [headaddress, setHeadAddress] = useState([]);
  const [order, setOrder] = useState("ASC");
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);

  const tokenAu = localStorage.getItem("token");
  const Customercode = localStorage.getItem("Cus_Code");
  const levelcode = localStorage.getItem("Level_Code");
  const product_detail_id = localStorage.getItem("product_detail_id");
  const PNo = localStorage.getItem("PO_No");
  const [headStatus, setHeadStatus] = useState([]);

  //getall
  const [products, setProducts] = useState([]);
  const [customers, setCustomers] = useState([]);
  const User_key = localStorage.getItem("User_Key");

  //Received
  const [receivedQTY, setReceivedQTY] = useState([]);
  const [remark, setRemark] = useState([]);
  const [qtyDeliver, setQtyDeliver] = useState([]);
  const [receivedId, setReceivedId] = useState([]);
  const [receivedId_detail, setReceivedId_detail] = useState([]);
  const [receivedId_Product, setReceivedId_Product] = useState([]);
  const [deliverDate, setDeliverDate] = useState([]);


  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { Option } = Select;
  
  const clicklogOut = () => {
    localStorage.clear();
    navigate("/");
  };


  const getDelivery = () => {
    fetch(config.ApiPathUrl_DevServer + "/Deliver/GetbyPo_Detail_id/" + `${product_detail_id}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        'Authorization': 'bearer '+tokenAu
      },
    }
    )
      .then((res) => res.json())
      .then((result) => {
        console.log(result);
        setAddPoNo(result);
      });
  };

  const getDeliveryByID = (id) => {
    fetch(config.ApiPathUrl_DevServer + "/Deliver/GetbyPo_Detail_id/" + `${id}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        'Authorization': 'bearer '+tokenAu
      },
    }
    )
      .then((res) => res.json())
      .then((result) => {
        setReceivedId_detail(result.po_Detail_Id);
        setReceivedId_Product(result.product_Id);
        setDeliverDate(result.deliver_dttm);
      });
  };

  const getMainPO = () => {
    fetch(config.ApiPathUrl_DevServer + "/Po/Po_no/" + `${PNo}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        'Authorization': 'bearer '+tokenAu
      },
    }
    )
      .then((res) => res.json())
      .then((result) => {
        console.log(result);
        setHeadPONO(result.po_no);
        setHeadDate(result.po_dttm);
        setHeadName(result.customer_id);
        setHeadAddress(result.po_Detail[0].product_id);
        setHeadStatus(result.status);
      });
  };
  const GetAllCustomer = () =>{
    fetch(config.ApiPathUrl_DevServer +"/Customer",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          'Authorization': 'bearer '+tokenAu
        },
      }
      )
        .then((res) => res.json())
        .then((result) => {
          //console.log(result);
          setCustomers(result);
        });

  };

  const GetAllProduct = () =>{
    fetch(config.ApiPathUrl_DevServer +"/Product",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          'Authorization': 'bearer '+tokenAu
        },
      }
      )
        .then((res) => res.json())
        .then((result) => {
          //console.log(result);
          setProducts(result);
        });
  };

  const sorting = (col) => {
    if (order === "ASC") {
      const sorted = [...addpono].sort((a, b) =>
        a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1
      );
      setAddPoNo(sorted);
      setOrder("DSC");
    }
    if (order === "DSC") {
      const sorted = [...addpono].sort((a, b) =>
        a[col].toLowerCase() < b[col].toLowerCase() ? 1 : -1
      );
      setAddPoNo(sorted);
      setOrder("ASC");
    }
  };


  const ReceivedItem = () =>{
    console.log(qtyDeliver);
    console.log(receivedQTY);
    if(qtyDeliver >= receivedQTY){
      AddDeliveryReceived();
    }else{
      alert("จำนวนเยอะเกินไป");
    }
  };

  const ReceivedShow = (id, qty) =>{
    setShow(true);
    getDeliveryByID(id);
    setQtyDeliver(qty);
    setReceivedId(id);
  };

  const AddDeliveryReceived = () => {
      fetch(config.ApiPathUrl_DevServer + "/Deliver", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          'Authorization': 'bearer '+tokenAu
        },
        body: JSON.stringify({
          id: receivedId,
          po_Detail_Id: receivedId_detail,
          product_Id: receivedId_Product,
          deliver_Qty: qtyDeliver,
          deliver_dttm: deliverDate,
          received_dttm: new Date(),
          received_Qty: receivedQTY,
          received_By: User_key,
          remark: remark,
        }),
      })
      .then(response => {
        console.log(response)
        if(response.status == 200){
          confirmAdd();
        }
      })
      .then(data => {
        console.log(data);
      })
  };

  const confirmAdd = () => {
    Swal.close();
    Swal.fire({
      icon: "success",
      title: "Success!",
      text: "You data has been added successfully.",
    }).then(() => {
      window.location.reload();
    });
  };



  useEffect(() => {
    //getItemPO();
    getMainPO(); 
    getDelivery();
    GetAllCustomer();
    GetAllProduct();
  }, []);
  return (
    <div className="scrollbar-main sidebar-light body">
      <div className="header">
        <div className="header-left">
          <div className="menu-icon dw dw-menu pointer"></div>
        </div>
        <div className="header-right">
          <div className="user-info-dropdown">
            <div className="dropdown">
              <a
                className="dropdown-toggle"
                href="/#"
                role="button"
                data-bs-toggle="dropdown"
              >
                <span className="user-icon">
                  <img src={user_icon} alt="" />
                </span>
                <span className="user-name">{UNAME}</span>
              </a>
              <div className="dropdown-menu dropdown-menu-right dropdown-menu-icon-list">
                <a className="dropdown-item" onClick={clicklogOut}>
                  <i className="dw dw-logout"></i> Log Out
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="left-side-bar">
        <div className="brand-logo">
          <a href="/home">
            <img src={logo} alt="" className="dark-logo" width="180px" />
          </a>
          <div className="close-sidebar" data-bs-toggle="left-sidebar-close">
            <i className="ion-close-round"></i>
          </div>
        </div>
        <Menu/>
      </div>
      <div className="mobile-menu-overlay"></div>

      <div className="main-container">
        <div className="pd-ltr-20">
          <div className="card-box mb-30">
            <div className="card-header">
              <div className="clearfix">
                <div className="pull-left">
                  <h2 className="h2">Delivery</h2>
                </div>
                <div className="pull-right">
                  <a className="btn btn-sm btn-outline-dark" href="/pohistory">
                    <i className="dw dw-left-chevron mr-2"></i>BACK
                  </a>
                </div>
              </div>
            </div>
            <div className="card-body">
              <div className="row mb-3">
                <div className="col-md-4">
                  <div className="form-group mb-2">
                    <label>PO No.</label>
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      value={headpono}
                      readOnly
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group mb-2">
                    <label>Date PO</label>
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      value={new Date(headdate).toLocaleDateString('en-GB')}
                      readOnly
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group mb-2">
                    <label>Status</label>
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      value={headStatus}
                      readOnly
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group mb-2">
                    <label>Customer Name</label>
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      value={customers.find((c) => c.id === headname)?.name}
                      readOnly
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group mb-2">
                    <label>Product Name</label>
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      value={products.find((p) => p.id === headaddress)?.productName}
                      readOnly
                    />
                  </div>
                </div>
              </div>
              <div className="table-responsive scrollbar-table">
                <table className="data-table table hover w-100 nowrap">
                  <thead>
                    <tr>
                      <th
                        className="text-center"
                        onClick={() => sorting("ItemCode")}
                      >
                        #
                      </th>
                      <th
                        className="text-center"
                        onClick={() => sorting("Item_Name")}
                      >
                        Date Deliver
                      </th>
                      <th className="text-center">Qty Deliver</th>
                      <th className="text-center">Date Received</th>
                      <th className="text-center">Qty Received</th>
                      <th className="text-center">Received BY</th>
                      <th
                        className="text-center"
                        onClick={() => sorting("Remark")}
                      >
                        Remark
                      </th>
                      <th className="datatable-nosort text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {addpono.map((item, i) => (
                      <tr key={i}>
                        <td className="text-center"></td>
                        <td className="text-center">{new Date(item.deliver_dttm).toLocaleDateString('en-GB')}</td>
                        <td className="text-center">{item.deliver_Qty}</td>
                        <td className="text-center">{new Date(item.received_dttm).toLocaleDateString('en-GB')}</td>
                        <td className="text-center">{item.received_Qty}</td>
                        <td className="text-center">{item.received_By}</td>
                        <td className="text-center">{item.Remark}</td>
                        <td className="text-center">
                            <div className="dropdown">
                              <a
                                className="btn btn-link font-24 p-0 line-height-1 no-arrow dropdown-toggle"
                                href="/#"
                                role="button"
                                data-bs-toggle="dropdown"
                              >
                                <i className="dw dw-more"></i>
                              </a>
                              <div className="dropdown-menu dropdown-menu-right dropdown-menu-icon-list">

                              {levelcode == "1000" || levelcode == "100" ? (
                                <a
                                  className="dropdown-item"
                                  // href="/adddelivery"
                                  onClick={() => ReceivedShow(item.id, item.deliver_Qty)}
                                  //  localStorage.setItem("ItemPO_No", item.po_no)
                                  //)}
                                >
                                  <i className="dw dw-truck mr-2"></i>Received
                                </a>
                                ) : null}
                                
                              </div>
                            </div>
                          </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>


              {/* <!-- Add Modal --> */}
            <Modal
              show={show}
              onHide={() => setShow(false)}
              dialogClassName="modal-90w"
              aria-labelledby="Customer"
            >
              <Modal.Header closeButton>
                <Modal.Title id="Customer">Received</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div>
                  <main className="content">
                    <Form
                      noValidate
                      //validated={validated}
                      //onSubmit={handleSubmit}
                    >
                      <Row className="justify-content-center">
                        <div className="col-md-12 col-xl-12">
                          <Col md={12} className="mb-6">
                            <Form.Group>
                              <Form.Label>Received Qty</Form.Label>
                              <Form.Label className="required-star">
                                *
                              </Form.Label>
                              <Form.Control
                                required
                                type="text"
                                placeholder="Qty"
                                //value={cus_name}
                                onChange={(e) => setReceivedQTY(e.target.value)}
                              />
                              {/* <span className="error" style={{color: "red"}}>{errorValidateUserName}</span> */}
                              {/* value={user_name}  onChange={(e) => handleChangeUserName(e)} */}
                            </Form.Group>
                          </Col>
                          <Col md={12} className="mb-6">
                            <Form.Group>
                              <Form.Label>Remark</Form.Label>
                              <textarea
                                className="form-control form-control-sm"
                                type="text"
                                onChange={(e) => setRemark(e.target.value)}
                              />
                            </Form.Group>
                          </Col>

                        </div>
                      </Row>
                      <div className="mt-3 d-flex justify-content-center"></div>
                    </Form>
                  </main>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="button"
                  className="btn btn-outline-secondary btn-sm"
                  data-dismiss="modal"
                  onClick={handleClose}
                >
                  <i className="micon dw dw-cancel mr-2"></i>CANCEL
                </button>
                <button
                  type="button"
                  className="btn btn-primary btn-sm"
                  //onClick="confirmAdd()"
                  onClick={() => ReceivedItem()}
                >
                  <i className="micon dw dw-add mr-2"></i>ADD
                </button>
              </Modal.Footer>
            </Modal>

            </div>
          </div>
          <div className="footer-wrap pd-20 mb-20 card-box">
            &copy; <span id="copy-year"></span> Copyright All rights reserved.
          </div>
        </div>
      </div>
    </div>
  );
}
