import React, { useState, useEffect, useRef } from "react";
import Menu from './Menu.js'; 
import { useNavigate } from "react-router-dom";
import config from "../appsetting.json";
import Modal from "react-bootstrap/Modal";
import { Col, Row, Form, Card } from "react-bootstrap";
import { Select } from "antd";
import DatePicker from "react-datepicker";
import Swal from "sweetalert2";
//photo
import logo from "./images/logo.svg";
import user_icon from "./images/user_icon.png";
// css
import "./styles/core.css";
import "./styles/icon-font.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "./styles/style.css";

export default function Customer() {
  const [CusData, setCusData] = useState([]);
  const Swal = require("sweetalert2");
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleEditClose = () => setShowEdit(false);
  
  const [show, setShow] = useState(false);
  const [showmodaladd, setShowModalAdd] = useState(false);
  const [showedit, setShowEdit] = useState(false);
  const navigate = useNavigate();
  
  const UNAME = localStorage.getItem("User_Id");
  const UCusKey = localStorage.getItem("User_Key");
  const tokenAu = localStorage.getItem("token");

  const [cuscodeshow, setCusCodeShow] = useState("");

  //get
  const [cus_name, setCusName] = useState("");
  const [cus_address, setCusAddress] = useState("");
  const [cus_contact, setCusContact] = useState("");
  const [cus_province, setCusProvince] = useState("");
  const [cus_area_id, setCusArea_id] = useState("");

  //getall
  const [editcus_name, setEditCusName] = useState("");
  const [editcusaddress, setEditCusAddress] = useState("");
  const [editcuscontact, setEditCusContact] = useState("");
  const [editcus_province, setEditCusProvince] = useState("");
  const [editcus_area_id, setEditCusArea_id] = useState("");

  const [customershow, setCustomershow] = useState([]);

  const [provinceOption,setProvinceOption] = useState([]);
  const [saleAreaOption,setSaleAreaOption] = useState([]);

  const [provinceEditOption,setProvinceEditOption] = useState([]);
  const [saleAreaEditOption,setSaleAreaEditOption] = useState([]);
  const { Option } = Select;


  const [validated, setValidated] = useState(false);
  const [visible, setVisible] = useState(false);  


  
  const getCustomer = () => {
     fetch(
      config.ApiPathUrl_DevServer + "/Customer",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          'Authorization': 'bearer '+tokenAu
        },
      }
      )
      .then((res) => res.json())
      .then((result) => {
        setCustomershow(result);
      });
  };


  const clicklogOut = () => {
    localStorage.clear();
    navigate("/");
  };


  const confirmAdd = () => {
    Swal.close();
    Swal.fire({
      icon: "success",
      title: "Success!",
      text: "Your data has been added successfully.",
    }).then(() => {
      window.location.reload();
    });
  };

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      AddCustomer();
    }
    setValidated(true);
  };


  const AddCustomer = () => {

    fetch(config.ApiPathUrl_DevServer + "/Customer", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${tokenAu}`
      },
      body: JSON.stringify({
        id: 0,
        name: cus_name,
        addr: cus_address,
        contact: cus_contact,
        province_id: provinceOption,
        area_id: saleAreaOption,
        active_status: "Y",
        create_dttm: new Date(),
        create_by: UCusKey,
        update_by: 0
      })
    })
      .then(response => {
        console.log(response)
        if(response.status == 200){
          confirmAdd();
        }
      })
      .then(data => {
        console.log(data);
      })
      .catch(error => console.error(error));
  };


  //edit
  const confirmEdit = () => {
    Swal.close();
    Swal.fire({
      icon: "success",
      title: "Success!",
      text: "You data has been saved successfully.",
    }).then(() => {
      window.location.reload();
    });
  };


  const loadCustomerByCode = async (cuscodeshow) => {
    try {
      await fetch(
       config.ApiPathUrl_DevServer + "/Customer/"+cuscodeshow,
       {
         method: "GET",
         headers: {
           "Content-Type": "application/json",
           'Authorization': 'bearer '+tokenAu
         },
      }).then((res) => res.json())
      .then((result) => {
        console.log(result);
        setEditCusName(result.name);
        setEditCusAddress(result.addr);
        setEditCusContact(result.contact);
        setProvinceEditOption(result.province_id);
        setSaleAreaEditOption(result.area_id);
      });
    } catch (error) {
      console.log(error);
      alert(error);
    }
  };


  const editCustomer = async () => {
      await fetch(config.ApiPathUrl_DevServer + "/Customer", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          'Authorization': 'bearer '+tokenAu
        },
        body: JSON.stringify({
          id: cuscodeshow,
          name: editcus_name,
          addr: editcusaddress,
          contact: editcuscontact,
          province_id: provinceEditOption,
          area_id: saleAreaEditOption,
          active_status: "Y",
          create_dttm: new Date(),
          update_by: UCusKey,
        }),
      })
      .then(response => {
        console.log(response)
        if(response.status == 200){
          confirmEdit();
        }
      })
      .then(data => {
        console.log(data);
      })
      .catch(error => console.error(error));
  };

  const confirmDelete = () => {
    Swal.close();
    Swal.fire({
      icon: "success",
      title: "Success!",
      text: "You data has been deleted successfully.",
    }).then(() => {
      window.location.reload();
    });
  };

  const deleteCustomer = (id) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${tokenAu}`);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "id": id
    });

    var requestOptions = {
      method: 'DELETE',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(config.ApiPathUrl_DevServer + "/Customer?id="+id, requestOptions)
      .then(response => response.text())
      .then(result => {
        confirmDelete();
      })
      .catch(error => console.log('error', error));
  };

  const loadgetProvince = async () => {

      await fetch(
        config.ApiPathUrl_DevServer + "/Province",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            'Authorization': 'bearer '+tokenAu
          },
        }
        )
        .then(response => response.json())
        .then(data => {
          const options = data.map(item => <Option key={item.id}>{item.name}</Option>);
          setProvinceOption(options);
        })
        .catch(error => {
          console.error(error);
          alert("Error loading Province.");
        });
  };

  const loadgetSaleArea = async () => {

    await fetch(
      config.ApiPathUrl_DevServer + "/SaleArea",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          'Authorization': 'bearer '+tokenAu
        },
      }
      )
      .then(response => response.json())
      .then(data => {
        const options = data.map(item => <Option key={item.id}>{item.name}</Option>);
        setSaleAreaOption(options);
      })
      .catch(error => {
        console.error(error);
        alert("Error loading product.");
      });
};

  const changeProvince = (e) => {
    console.log(e);
    let d = document.getElementsByClassName("ant-select-selector");
    if (e !== "" && e !== undefined) {
      if (d[0].classList.contains("validation-select-invalid")) {
        d[0].classList.remove("validation-select-invalid");
        d[0].className += " validation-select-valid";
      }
      setProvinceOption(e);
    } else {
      if (d[0].classList.contains("validation-select-valid")) {
        d[0].classList.remove("validation-select-valid");
        d[0].className += " validation-select-invalid";
      }
      setProvinceOption("");
    }
  };

  const changeSaleArea = (e) => {
    console.log(e);
    let d = document.getElementsByClassName("ant-select-selector");
    if (e !== "" && e !== undefined) {
      if (d[0].classList.contains("validation-select-invalid")) {
        d[0].classList.remove("validation-select-invalid");
        d[0].className += " validation-select-valid";
      }
      setSaleAreaOption(e);
    } else {
      if (d[0].classList.contains("validation-select-valid")) {
        d[0].classList.remove("validation-select-valid");
        d[0].className += " validation-select-invalid";
      }
      setSaleAreaOption("");
    }
  };

  const changeEditProvince = (e) => {
    console.log(e);
    let d = document.getElementsByClassName("ant-select-selector");
    if (e !== "" && e !== undefined) {
      if (d[0].classList.contains("validation-select-invalid")) {
        d[0].classList.remove("validation-select-invalid");
        d[0].className += " validation-select-valid";
      }
      setProvinceEditOption(e);
    } else {
      if (d[0].classList.contains("validation-select-valid")) {
        d[0].classList.remove("validation-select-valid");
        d[0].className += " validation-select-invalid";
      }
      setProvinceEditOption("");
    }
  };

  const changeEditSaleArea = (e) => {
    console.log(e);
    let d = document.getElementsByClassName("ant-select-selector");
    if (e !== "" && e !== undefined) {
      if (d[0].classList.contains("validation-select-invalid")) {
        d[0].classList.remove("validation-select-invalid");
        d[0].className += " validation-select-valid";
      }
      setSaleAreaEditOption(e);
    } else {
      if (d[0].classList.contains("validation-select-valid")) {
        d[0].classList.remove("validation-select-valid");
        d[0].className += " validation-select-invalid";
      }
      setSaleAreaEditOption("");
    }
  };
 
  useEffect(()=>{
      getCustomer();
      loadgetProvince();
      loadgetSaleArea();
  },[]);


  return (
    <div className="scrollbar-main sidebar-light body">
      <div className="header">
        <div className="header-left">
          <div className="menu-icon dw dw-menu pointer"></div>
        </div>
        <div className="header-right">
          <div className="user-info-dropdown">
            <div className="dropdown">
              <a
                className="dropdown-toggle"
                href="/#"
                role="button"
                data-bs-toggle="dropdown"
              >
                <span className="user-icon">
                  <img src={user_icon} alt="" width="100%"/>
                </span>
                <span className="user-name">{UNAME}</span>
              </a>
              <div className="dropdown-menu dropdown-menu-right dropdown-menu-icon-list">
                <a className="dropdown-item" onClick={clicklogOut}>
                  <i className="dw dw-logout"></i> Log Out
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="left-side-bar">
        <div className="brand-logo">
          <a href="/home">
            <img src={logo} alt="" className="dark-logo" width="180px" />
          </a>
          <div className="close-sidebar" data-bs-toggle="left-sidebar-close">
            <i className="ion-close-round"></i>
          </div>
        </div>
        <Menu/>
      </div>
      <div className="mobile-menu-overlay"></div>

      <div className="main-container">
        <div className="pd-ltr-20">
          <div className="card-box mb-30">
            <div className="card-body">
              <div className="clearfix">
                <div className="pull-left">
                  <h2 className="h2">Customer Management</h2>
                </div>
                <div className="pull-right">
                  <button
                    type="button"
                    className="btn btn-primary btn-sm"
                    // data-bs-toggle="modal"
                    // data-target="#add-data"
                    onClick={() => setShow(true)}
                  >
                    <i className="micon dw dw-add-user mr-2"></i>ADD CUSTOMER
                  </button>
                </div>
              </div>

              <div className="table-responsive scrollbar-table">
                <table className="data-table table hover w-100 nowrap">
                  <thead>
                    <tr>
                      <th className="text-center">Customer Code</th>
                      <th className="text-center">Name</th>
                      <th className="text-center">Address</th>
                      <th className="text-center">Contact Us</th>
                      <th className="datatable-nosort text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {customershow.map((cus) => (
                      <tr key={cus.id} >
                        <td className="text-center">{cus.id}</td>
                        <td className="text-center">{cus.name}</td>
                        <td className="text-center">{cus.addr}</td>
                        <td className="text-center">{cus.contact}</td>
                        <td className="text-center">
                          <div className="dropdown">
                            <a
                              className="btn btn-link font-24 p-0 line-height-1 no-arrow dropdown-toggle"
                              href="/#"
                              role="button"
                              data-bs-toggle="dropdown"
                            >
                              <i className="dw dw-more"></i>
                            </a>
                            <div className="dropdown-menu dropdown-menu-right dropdown-menu-icon-list">
                              <a
                                className="dropdown-item"
                                // href="/#"
                                // data-bs-toggle="modal"
                                // data-target="#edit-data"
                                onClick={() => (
                                  loadCustomerByCode(cus.id),
                                  setShowEdit(true),
                                  setCusCodeShow(cus.id)
                                  
                                )}
                              >
                                <i className="dw dw-edit2"></i> Edit
                              </a>
                              <a
                                className="dropdown-item"
                                onClick={() => deleteCustomer(cus.id)}
                              >
                                <i className="dw dw-trash1"></i> Delete
                              </a>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>

            {/* <!-- Add Modal --> */}
            <Modal
              show={show}
              onHide={() => setShow(false)}
              dialogClassName="modal-90w"
              aria-labelledby="Customer"
            >
              <Modal.Header closeButton>
                <Modal.Title id="Customer">Add Customer</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div>
                  <main className="content">
                    <Form
                      noValidate
                      validated={validated}
                      onSubmit={handleSubmit}
                    >
                      <Row className="justify-content-center">
                        <div className="col-md-6 col-xl-12">
                          <Col md={12} className="mb-3">
                            <Form.Group>
                              <Form.Label>CustomerName</Form.Label>
                              <Form.Label className="required-star">
                                *
                              </Form.Label>
                              <Form.Control
                                required
                                type="text"
                                placeholder="Name"
                                //value={cus_name}
                                onChange={(e) => setCusName(e.target.value)}
                              />
                              {/* <span className="error" style={{color: "red"}}>{errorValidateUserName}</span> */}
                              {/* value={user_name}  onChange={(e) => handleChangeUserName(e)} */}
                            </Form.Group>
                          </Col>
                          <Col md={12} className="mb-3">
                            <Form.Group>
                              <Form.Label>CustomerAddress</Form.Label>
                              <Form.Label className="required-star">
                                *
                              </Form.Label>
                              <Form.Control
                                required
                                type="text"
                                placeholder="Address"
                                // value={cus_address}
                                onChange={(e) => setCusAddress(e.target.value)}
                              />
                            </Form.Group>
                          </Col>
                          <Col md={12} className="mb-3">
                            <Form.Group>
                              <Form.Label>CustomerContact</Form.Label>
                              <Form.Label className="required-star">
                                *
                              </Form.Label>
                              <Form.Control
                                required
                                type="text"
                                placeholder="Contact"
                                //value={cus_contact}
                                onChange={(e) => setCusContact(e.target.value)}
                              />
                            </Form.Group>
                          </Col>

                          <Col md={12} className="mb-3">
                            <Form.Group>
                              <Form.Label>Province</Form.Label>
                              <Form.Label className="required-star">
                                *
                              </Form.Label>
                              <Select
                                style={{ width: "100%" }}
                                // className="custom-select custom-select-sm"
                                showSearch
                                allowClear
                                required
                                placeholder="Select a Item"
                                optionFilterProp="children"
                                onChange={changeProvince}
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                                }
                              >
                                {provinceOption}
                              </Select>
                            </Form.Group>
                          </Col>

                          <Col md={12} className="mb-3">
                            <Form.Group>
                              <Form.Label>Area</Form.Label>
                              <Form.Label className="required-star">
                                *
                              </Form.Label>
                              <Select
                                style={{ width: "100%" }}
                                // className="custom-select custom-select-sm"
                                showSearch
                                allowClear
                                required
                                placeholder="Select a Item"
                                optionFilterProp="children"
                                onChange={changeSaleArea}
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                                }
                              >
                                {saleAreaOption}
                              </Select>
                            </Form.Group>
                          </Col>

                        </div>
                      </Row>
                      <div className="mt-3 d-flex justify-content-center"></div>
                    </Form>
                  </main>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="button"
                  className="btn btn-outline-secondary btn-sm"
                  data-dismiss="modal"
                  onClick={handleClose}
                >
                  <i className="micon dw dw-cancel mr-2"></i>CANCEL
                </button>
                <button
                  type="button"
                  className="btn btn-primary btn-sm"
                  //onClick="confirmAdd()"
                  onClick={AddCustomer}
                >
                  <i className="micon dw dw-add mr-2"></i>ADD
                </button>
              </Modal.Footer>
            </Modal>

            {/* <!-- Edit Modal --> */}
            <Modal
              show={showedit}
              onHide={() => setShowEdit(false)}
              dialogClassName="modal-90w"
              aria-labelledby="EditCustomer"
            >
              <Modal.Header closeButton>
                <Modal.Title id="EditCustomer">Edit Customer</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div>
                  <main className="content">
                    <Form
                      noValidate
                      validated={validated}
                      onSubmit={handleSubmit}
                    >
                      <Row className="justify-content-center">
                        <div className="col-md-6 col-xl-12">
                          <Col md={12} className="mb-3">
                            <Form.Group>
                              <Form.Label>CustomerCode</Form.Label>
                              <Form.Label className="required-star">
                                *
                              </Form.Label>
                              <Form.Control
                                required
                                type="text"
                                placeholder="CustomerCode"
                                value={cuscodeshow}
                                readOnly
                              />
                            </Form.Group>
                            <Form.Group>
                              <Form.Label>CustomerName</Form.Label>
                              <Form.Label className="required-star">
                                *
                              </Form.Label>
                              <Form.Control
                                required
                                type="text"
                                placeholder="Name"
                                value={editcus_name}
                                onChange={(e) => setEditCusName(e.target.value)}
                              />
                            </Form.Group>
                          </Col>
                          <Col md={12} className="mb-3">
                            <Form.Group>
                              <Form.Label>CustomerAddress</Form.Label>
                              <Form.Label className="required-star">
                                *
                              </Form.Label>
                              <Form.Control
                                required
                                type="text"
                                placeholder="Address"
                                value={editcusaddress}
                                onChange={(e) =>
                                  setEditCusAddress(e.target.value)
                                }
                              />
                            </Form.Group>
                          </Col>
                          <Col md={12} className="mb-3">
                            <Form.Group>
                              <Form.Label>CustomerContact</Form.Label>
                              <Form.Label className="required-star">
                                *
                              </Form.Label>
                              <Form.Control
                                required
                                type="text"
                                placeholder="Contact"
                                value={editcuscontact}
                                onChange={(e) =>
                                  setEditCusContact(e.target.value)
                                }
                              />
                            </Form.Group>
                          </Col>

                          <Col md={12} className="mb-3">
                            <Form.Group>
                              <Form.Label>Province</Form.Label>
                              <Form.Label className="required-star">
                                *
                              </Form.Label>
                              <Select
                                style={{ width: "100%" }}
                                // className="custom-select custom-select-sm"
                                showSearch
                                allowClear
                                required
                                placeholder="Select a Item"
                                optionFilterProp="children"
                                value={provinceEditOption}
                                onChange={changeEditProvince}
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                                }
                              >
                                {provinceOption}
                              </Select>
                            </Form.Group>
                          </Col>

                          <Col md={12} className="mb-3">
                            <Form.Group>
                              <Form.Label>Area</Form.Label>
                              <Form.Label className="required-star">
                                *
                              </Form.Label>
                              <Select
                                style={{ width: "100%" }}
                                // className="custom-select custom-select-sm"
                                showSearch
                                allowClear
                                required
                                placeholder="Select a Item"
                                optionFilterProp="children"
                                value={saleAreaEditOption}
                                onChange={changeEditSaleArea}
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                                }
                              >
                                {saleAreaOption}
                              </Select>
                            </Form.Group>
                          </Col>


                        </div>
                      </Row>
                      <div className="mt-3 d-flex justify-content-center"></div>
                    </Form>
                  </main>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="button"
                  className="btn btn-outline-secondary btn-sm"
                  data-dismiss="modal"
                  onClick={handleClose}
                >
                  <i className="micon dw dw-cancel mr-2"></i>CANCEL
                </button>
                <button
                  type="button"
                  className="btn btn-primary btn-sm"
                  onClick={editCustomer}
                >
                  <i className="micon dw dw-diskette1 mr-2"></i>SAVE
                </button>
              </Modal.Footer>
            </Modal>
          </div>
          <div className="footer-wrap pd-20 mb-20 card-box">
            &copy; <span id="copy-year"></span> Copyright All rights reserved.
          </div>
        </div>
      </div>
    </div>
  );
}
