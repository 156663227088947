import React, { useState, useEffect } from "react";
import Menu from './Menu.js';
import Modal from "react-bootstrap/Modal";
import { Select } from "antd";
import { Col, Row, Form, Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import config from "../appsetting.json";
// รูป
import logo from "./images/logo.svg";
import user_icon from "./images/user_icon.png";
// css
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "./styles/core.css";
import "./styles/icon-font.min.css";
import "./fonts/dropways.css";
import "./styles/style.css";
import "react-datepicker/dist/react-datepicker.css";
//หน้าจอ

const { Option } = Select;

export default function HospitalListViewDelivery() {
  // const [stockData, setStockData] = useState([]);
  const [cus_name, setCus_NAME] = useState([]);
  const navigate = useNavigate();
  const UNAME = localStorage.getItem("UserName");
  const CusCode = localStorage.getItem("Cus_Code");
  const [deliveryinfo, setDeliveryInfo] = useState([]);
  // const [order, setOrder] = useState("ASC");
  const [visiblePurchaseOrder, setVisiblePurchaseOrder] = useState(false);
  const [visibleuseraccount, setVisibleUserAccount] = useState(false);
  const levelcode = localStorage.getItem("Level_Code");

  const clicklogOut = () => {
    localStorage.clear();
    navigate("/");
  };

  const getStockList = () => {
    fetch(config.ApiPathUrl_DevServer + "/getAllStockList/" + `${CusCode}`)
      .then((res) => res.json())
      .then((result) => {
        // setStockData(result);
        setCus_NAME(result[0].Cus_Name);
      });
  };
  const GetInfoDeliveryByCuscode = () => {
    fetch(
      config.ApiPathUrl_DevServer + "/checkDeliveryByCusCode/" + `${CusCode}`
    )
      .then((res) => res.json())
      .then((result) => {
        setDeliveryInfo(result);
      });
  };

  // const sorting = (col) => {
  //   if (order === "ASC") {
  //     const sorted = [...stockData].sort((a, b) =>
  //       a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1
  //     );
  //     setStockData(sorted);
  //     setOrder("DSC");
  //   }
  //   if (order === "DSC") {
  //     const sorted = [...stockData].sort((a, b) =>
  //       a[col].toLowerCase() < b[col].toLowerCase() ? 1 : -1
  //     );
  //     setStockData(sorted);
  //     setOrder("ASC");
  //   }
  // };

  useEffect(() => {
    getStockList(); 
    GetInfoDeliveryByCuscode();
  }, []);
  return (
    <div className="scrollbar-main sidebar-light body">
      <div className="header">
        <div className="header-left">
          <div className="menu-icon dw dw-menu pointer"></div>
        </div>
        <div className="header-right">
          <div className="user-info-dropdown">
            <div className="dropdown">
              <a
                className="dropdown-toggle"
                href="/#"
                role="button"
                data-bs-toggle="dropdown"
              >
                <span className="user-icon">
                  <img src={user_icon} alt="" />
                </span>
                <span className="user-name">{UNAME}</span>
              </a>
              <div className="dropdown-menu dropdown-menu-right dropdown-menu-icon-list">
                <a className="dropdown-item" onClick={clicklogOut}>
                  <i className="dw dw-logout"></i> Log Out
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="left-side-bar">
        <div className="brand-logo">
          <a href="/home">
            <img src={logo} alt="" className="dark-logo" width="180px" />
          </a>
          <div className="close-sidebar" data-bs-toggle="left-sidebar-close">
            <i className="ion-close-round"></i>
          </div>
        </div>
        <div className="menu-block customscroll">
          <div className="sidebar-menu icon-list-style-2">
            <ul id="accordion-menu" className="fa-ul pl-0 m-0">
              <li>
                <a href="/home" className="dropdown-toggle no-arrow ">
                  <span className="micon dw dw-house1"></span>
                  <span className="mtext">Home</span>
                </a>
              </li>
              <li>
                <a href="/hospitallist" className="dropdown-toggle no-arrow">
                  <span className="micon dw dw-hospital"></span>
                  <span className="mtext">Hospital List</span>
                </a>
              </li>
              <li>
                <a href="/stockList" className="dropdown-toggle no-arrow">
                  <span className="micon dw dw-inbox1"></span>
                  <span className="mtext">Stock List</span>
                </a>
              </li>
              {visiblePurchaseOrder && (
                <li>
                  <a href="/pohistory" className="dropdown-toggle no-arrow">
                    <span className="micon dw dw-inbox1"></span>
                    <span className="mtext">PurchaseOrder List</span>
                  </a>
                </li>
              )}
              {visibleuseraccount && (
                <li className="dropdown">
                  <a className="dropdown-toggle" data-bs-toggle="dropdown">
                    <span className="micon dw dw-user1"></span>
                    <span className="mtext">User Account</span>
                  </a>
                  <ul className="submenu dropdown-menu">
                    <li>
                      <a href="/customer" className="dropdown-item">
                        Customer
                      </a>
                    </li>
                    <li>
                      <a href="/user" className="dropdown-item">
                        User
                      </a>
                    </li>
                    <li>
                      <a href="/level" className="dropdown-item">
                        Level
                      </a>
                    </li>
                  </ul>
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>
      <div className="mobile-menu-overlay"></div>

      <div className="main-container">
        <div className="pd-ltr-20">
          <div className="card-box mb-30">
            <div className="card-body">
              <div className="clearfix">
                <div className="pull-left">
                  <h2 className="h2">Delivery</h2>
                  <h5 className="text-green">{cus_name}</h5>
                </div>
                <div className="pull-right">
                  <a
                    className="btn btn-sm btn-outline-dark mr-2"
                    href="/hospitallist"
                  >
                    <i className="dw dw-left-chevron mr-2"></i>BACK
                  </a>
                </div>
              </div>

              <div className="table-responsive scrollbar-table">
                <table className="data-table table hover w-100 nowrap">
                  <thead>
                    <tr>
                      <th
                        className="text-center"
                        // onClick={() => sorting("Stock_Key")}
                      >
                        Key
                      </th>
                      <th
                        className="text-center"
                        // onClick={() => sorting("Create_Date")}
                      >
                        PO NO.
                      </th>
                      <th
                        className="text-center"
                        // onClick={() => sorting("Item_Name")}
                      >
                        ALink Key
                      </th>
                      <th
                        className="text-center"
                        // onClick={() => sorting("Sales")}
                      >
                        Customer Code
                      </th>
                      <th
                        className="text-center"
                        // onClick={() => sorting("Sales")}
                      >
                        Project Code
                      </th>
                      <th
                        className="text-center"
                        // onClick={() => sorting("Sales")}
                      >
                        Delivery Date
                      </th>
                      <th className="text-center">Balance</th>
                      <th className="text-center">Delivery</th>
                      <th className="text-center">Status</th>
                      <th className="datatable-nosort text-center">ItemCode</th>
                    </tr>
                  </thead>
                  <tbody>
                    {deliveryinfo.map((item, i) => (
                      <tr key={i}>
                        <td className="text-center">{item.Delivery_Key}</td>
                        <td className="text-center">{item.PO_No}</td>
                        <td className="text-center">{item.Alink_Key}</td>
                        <td className="text-center">{item.Cus_Code}</td>
                        <td className="text-center">{item.ProjectCode}</td>
                        <td className="text-center">{item.PlanDeliveryDate}</td>
                        <td className="text-center">{item.Balance}</td>
                        <td className="text-center">{item.Delivery}</td>
                        <td className="text-center">{item.DeliveryStatus}</td>
                        <td className="text-center">{item.ItemCode}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="footer-wrap pd-20 mb-20 card-box">
            &copy; <span id="copy-year"></span> Copyright All rights reserved.
          </div>
        </div>
      </div>
    </div>
  );
}
