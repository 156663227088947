import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import LoadingScreen from "./LoadingScreen";
import { useParams, useLocation } from 'react-router-dom';
//photo
import TGL_logo from "./images/TGL_logo.png";
import login_delivery from "./images/login_delivery.png";
import config from "../appsetting.json";
// css
// import "bootstrap/dist/css/bootstrap.min.css";
// import "bootstrap/dist/js/bootstrap.bundle.min";
// import "./styles/style.css";
// import "./styles/icon-font.css";
// import "./styles/icon-font.min.css";
// import "./styles/theme.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "./styles/core.css";
import "./styles/style.css";
import "./styles/icon-font.min.css";

export default function LoginCustomer(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const ponoId = searchParams.get('pono_id');

  const [UID, setUserid] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  //const { pono_id } = useParams();
  localStorage.setItem("PO_No", ponoId);

  const handleSubmit = async (e) => {
    setLoading(true);
    if (UID === "" || UID === null || password === "" || password === null) {
      alert("ข้อมูลไม่ครบถ้วน กรุณากรอกข้อมูลให้ครบ");
      window.location.reload();
    } else {
      setLoading(true);
      await fetch(
        config.ApiPathUrl_DevServer + "/token",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userid: UID,
            password: password,
          }),
        }
      )
        .then((res) => res.json())
        .then(async (data) => {
          console.log(data);
          if (data.token != "") {
            localStorage.setItem('token', data.token);
            console.log(UID);
            getUser(UID);
            
          } else {
            alert("Username or Password Incorrect,Please try again.");
            setLoading(false);
            window.location.reload();
          }
        })
        .catch((err) => console.log(err));
        setLoading(true);
        //window.location.reload();
    }
  };

  const getUser = (UID) => {
    const tokenAu = localStorage.getItem("token");
   
    fetch(
      config.ApiPathUrl_DevServer + "/Users/userid/"+UID,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          'Authorization': 'bearer '+tokenAu
        } 
      }
      )
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error('Network response was not ok.');
        }
      })
      .then((result) => {
        console.log(result);
        //setUserData(result.Data);
    
        const usr_key = result.id;
        const username = result.firstName;
        const UID = result.userId;
        //const password = result.password;
        const user_level = result.userLevel;
        const cus_code = result.customer_id;
          //ส่งค่าไปหน้าอื่น
        localStorage.setItem("User_Key", usr_key);
        localStorage.setItem("UserName", username);
        localStorage.setItem("User_Id", UID);
        //localStorage.setItem("PassWords_Id", password);
        localStorage.setItem("Level_Code", user_level);
        localStorage.setItem("Cus_Code", cus_code);
        localStorage.setItem("status_qrcode", "qrcode");

        if(result.userLevel == 10){
          navigate("/deliverlistcustomer");
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  return (
    <>
      {loading === false ? (
        <div className="login-page scrollbar-main">
          <div className="login-wrap d-flex align-items-center flex-wrap justify-content-center">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-sm-6 col-lg-7">
                  <img src={login_delivery} alt="" width="100%" />
                </div>
                <div className="col-sm-6 col-lg-5">
                  <div className="login-box bg-white box-shadow border-radius-10">
                    <div className="login-title text-center">
                      <img src={TGL_logo} className="mb-4" alt="" width="100" />
                      <h2 className="text-center text-secondary">
                        Login To Syringe Stock
                      </h2>
                    </div>
                    <form onSubmit={(e) => handleSubmit(e)}>
                      <div className="input-group custom">
                        <input
                          type="text"
                          className="form-control form-control-lg"
                          placeholder="UserID"
                          value={UID}
                          onChange={(e) => setUserid(e.target.value)}
                          required
                        />
                        <div className="input-group-append custom">
                          <span className="input-group-text">
                            <i className="icon-copy dw dw-user1"></i>
                          </span>
                        </div>
                      </div>
                      <div className="input-group custom">
                        <input
                          type="password"
                          className="form-control form-control-lg"
                          placeholder="**********"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          required
                        />
                        <div className="input-group-append custom">
                          <span className="input-group-text">
                            <i className="dw dw-padlock1"></i>
                          </span>
                        </div>
                      </div>
                      <div className="row pb-30">
                        <div className="col-6"></div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="input-group mb-0">
                            <button
                              className="btn btn-primary btn-lg btn-block w-100"
                              type="submit"
                              // onClick ={navigate("/home/")}
                            >
                              Sign In
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <LoadingScreen />
      )}
    </>
  );
}
