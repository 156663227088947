import React, { useState, useEffect } from "react";
import Menu from './Menu.js';
import { useNavigate } from "react-router-dom";
import config from "../appsetting.json";
import Modal from "react-bootstrap/Modal";
//photo
import loginpageimg from "./images/loginpageimg.png";
import TGL_logo from "./images/TGL_logo.png";
import logo from "./images/logo.svg";
import user_icon from "./images/user_icon.png";
// css
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "./styles/core.css";
import "./styles/style.css";
import "./styles/icon-font.min.css";

export default function History() {
  const [historyData, setHistoryData] = useState([]);
  const [show, setShow] = useState(false);
  const [showaddpo, setShowAddPo] = useState(false);
  const [showQr, setShowQr] = useState(false);
  const [order, setOrder] = useState("ASC");
  const navigate = useNavigate();
  const UNAME = localStorage.getItem("UserName");
  const [visible, setVisible] = useState(false);  
  const levelcode = localStorage.getItem("Level_Code");  

  const clicklogOut = () => {
    localStorage.clear();
    navigate("/");
  };

  const getStockList = () => {
    fetch(
      config.ApiPathUrl_DevServer +"/getAllHistory")
      .then((res) => res.json())
      .then((result) => {
        console.log(result.Data);
        setHistoryData(result.Data);
      });
  };
  const sorting = (col) => {
    if (order === "ASC") {
      const sorted = [...historyData].sort((a, b) =>
        a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1
      );
      setHistoryData(sorted);
      setOrder("DSC");
    }
    if (order === "DSC") {
      const sorted = [...historyData].sort((a, b) =>
        a[col].toLowerCase() < b[col].toLowerCase() ? 1 : -1
      );
      setHistoryData(sorted);
      setOrder("ASC");
    }
  };

  useEffect(() => {
    getStockList(); 
  }, []);
  return (
    <div className="scrollbar-main sidebar-light body">
      <div className="header">
        <div className="header-left">
          <div className="menu-icon dw dw-menu pointer"></div>
        </div>
        <div className="header-right">
          <div className="user-info-dropdown">
            <div className="dropdown">
              <a
                className="dropdown-toggle"
                href="/#"
                role="button"
                data-bs-toggle="dropdown"
              >
                <span className="user-icon">
                  <img src={user_icon} alt="" />
                </span>
                <span className="user-name">{UNAME}</span>
              </a>
              <div className="dropdown-menu dropdown-menu-right dropdown-menu-icon-list">
                <a className="dropdown-item" onClick={clicklogOut}>
                  <i className="dw dw-logout"></i> Log Out
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="left-side-bar">
        <div className="brand-logo">
          <a href="/home">
            <img src={logo} alt="" className="dark-logo" width="180px" />
          </a>
          <div className="close-sidebar" data-bs-toggle="left-sidebar-close">
            <i className="ion-close-round"></i>
          </div>
        </div>
        <div className="menu-block customscroll">
          <div className="sidebar-menu icon-list-style-2">
            <ul id="accordion-menu" className="fa-ul pl-0 m-0">
              <li>
                <a href="/home" className="dropdown-toggle no-arrow">
                  <span className="micon dw dw-house1"></span>
                  <span className="mtext">Home</span>
                </a>
              </li>
              <li>
                <a href="/hospitallist" className="dropdown-toggle no-arrow">
                  <span className="micon dw dw-hospital"></span>
                  <span className="mtext">Hospital List</span>
                </a>
              </li>
              <li>
                <a href="/stockList" className="dropdown-toggle no-arrow">
                  <span className="micon dw dw-inbox1"></span>
                  <span className="mtext">Stock List</span>
                </a>
              </li>
              {visible && 
              <li>
                <a href="/pohistory" className="dropdown-toggle no-arrow">
                  <span className="micon dw dw-inbox1"></span>
                  <span className="mtext">PurchaseOrder List</span>
                </a>
              </li>}
              {visible && 
              <li className="dropdown">
                <a
                  // href="javascript:;"
                  className="dropdown-toggle"
                  data-bs-toggle="dropdown"
                >
                  <span className="micon dw dw-user1"></span>
                  <span className="mtext">User Account</span>
                </a>
                <ul className="submenu dropdown-menu">
                  <li>
                    <a href="/customer" className="dropdown-item">
                      Customer
                    </a>
                  </li>
                  <li>
                    <a href="/user" className="dropdown-item">
                      User
                    </a>
                  </li>
                  <li>
                    <a href="/level" className="dropdown-item">
                      Level
                    </a>
                  </li>
                </ul>
              </li>}
            </ul>
          </div>
        </div>
      </div>
      <div className="mobile-menu-overlay"></div>

      <div className="main-container">
        <div className="pd-ltr-20">
          <div className="card-box mb-30">
            <div className="card-body">
              <div className="clearfix">
                <div className="pull-left">
                  <h2 className="h2">History</h2>
                </div>
                <div className="pull-right">
                  <a
                    className="btn btn-sm btn-outline-dark mr-2"
                    href="/stockList"
                  >
                    <i className="dw dw-left-chevron mr-2"></i>BACK
                  </a>
                  <a
                    className="btn btn-sm btn-primary"
                    onClick={() => setShowAddPo(true)}
                  >
                    <i className="dw dw-truck mr-2"></i>Add PO
                  </a>
                </div>
              </div>

              <div className="table-responsive scrollbar-table">
                <table className="data-table table hover w-100 nowrap">
                  <thead>
                    <tr>
                      <th
                        className="text-center"
                        onClick={() => sorting("History_Key")}
                      >
                        #
                      </th>
                      <th
                        className="text-center"
                        onClick={() => sorting("PO_No")}
                      >
                        PO No.
                      </th>
                      <th
                        className="text-center"
                        onClick={() => sorting("Invoice_No")}
                      >
                        Invoice No.
                      </th>
                      <th className="text-center">Qty</th>
                      <th
                        className="text-center"
                        onClick={() => sorting("Customer_Name")}
                      >
                        CustomerName
                      </th>
                      <th className="text-center">ProjectCode</th>
                      <th className="text-center">Date</th>
                      <th className="text-center">Status</th>{" "}
                      {/* //ถ้าส่งแล้วจะขึ้น Complete ถ้า Complete ครบแล้วจะไม่สามารถบิกได้ */}
                      <th className="text-center">Remark</th>
                      <th className="datatable-nosort text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {historyData.map((item, i) => (
                      <tr key={i}>
                        <td className="text-center">{item.History_Key}</td>
                        <td className="text-center">{item.PO_No}</td>
                        <td className="text-center">{item.Invoice_No}</td>
                        <td className="text-center">{item.Qty}</td>
                        <th className="text-center">{item.Customer_Name}</th>
                        <td className="text-center">S</td>
                        <td className="text-center">{item.Dates}</td>
                        <th className="text-center">{item.Status}</th>
                        <td></td>
                        <td className="text-center">
                          <div className="dropdown">
                            <a
                              className="btn btn-link font-24 p-0 line-height-1 no-arrow dropdown-toggle"
                              href="/#"
                              role="button"
                              data-bs-toggle="dropdown"
                            >
                              <i className="dw dw-more"></i>
                            </a>
                            <div className="dropdown-menu dropdown-menu-right dropdown-menu-icon-list">
                              <a
                                className="dropdown-item"
                                onClick={() => setShow(true)}
                              >
                                <i className="dw dw-truck mr-2"></i>Add Delivery
                              </a>
                              <a
                                className="dropdown-item"
                                href="/purchaseorder"
                              >
                                <i className="dw dw-sheet"></i> Purchase Order
                              </a>
                              <a className="dropdown-item" href="/delivery">
                                <i className="dw dw-truck"></i> Delivery Notes
                              </a>
                              <a
                                className="dropdown-item"
                                // href="/#"
                                // data-bs-toggle="modal"
                                // data-target="#qrcode-data"
                                onClick={() => setShowQr(true)}
                              >
                                <i className="dw dw-print"></i> Print QR
                              </a>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="row mb-2 mt-3">
                <div className="col-md-4 offset-md-8">
                  <div className="form-group mb-2 d-flex justify-content-end align-items-baseline">
                    <label className="mr-2">Total</label>
                    <input
                      type="text"
                      className="form-control form-control-sm mr-2"
                      placeholder="800.00"
                      // onkeypress="return NumberFloatAndOneDOTSign(event)"
                      style={{ width: "170px" }}
                      readOnly
                    />
                    <label>Qty</label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <!-- Add Modal --> */}
          <Modal
            show={showaddpo}
            onHide={() => setShowAddPo(false)}
            dialogClassName="modal-90w"
            aria-labelledby="AddPo"
          >
            <Modal.Header closeButton>
              <Modal.Title id="AddPo">Add Purcahse Order</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group mb-2">
                    <label>CustomerName</label>
                    <input
                      className="form-control form-control-sm"
                      type="text"
                      required
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group mb-2">
                    <label>PO No.</label>
                    <input
                      className="form-control form-control-sm"
                      type="text"
                      required
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group mb-2">
                    <label>ProjectCode</label>
                    <input
                      className="form-control form-control-sm"
                      type="text"
                      required
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group mb-2">
                    <label>Date</label>
                    <input
                      className="form-control form-control-sm date-picker"
                      type="text"
                      required
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group mb-2">
                    <label>กำหนดส่ง</label>
                    <input
                      className="form-control form-control-sm date-picker"
                      type="text"
                      required
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group mb-2">
                    <label>Remark</label>
                    <input
                      className="form-control form-control-sm"
                      type="text"
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-3 d-flex align-items-center">
                  <button
                    type="button"
                    className="btn btn-sm btn-primary mt-md-4 mt-2"
                  >
                    <i className="dw dw-add mr-2"></i>ADD
                  </button>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                className="btn btn-outline-secondary btn-sm"
                data-dismiss="modal"
              >
                <i className="micon dw dw-cancel mr-2"></i>CANCEL
              </button>
              <button
                type="button"
                className="btn btn-primary btn-sm"
                //  onClick="confirmAdd()"
              >
                <i className="micon dw dw-diskette1 mr-2"></i>SAVE
              </button>
            </Modal.Footer>
          </Modal>
          {/* <!-- Add Modal --> */}
          <Modal
            show={show}
            onHide={() => setShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="AddDelivery"
          >
            <Modal.Header closeButton>
              <Modal.Title id="AddDelivery">Add Delivery</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row">
                <div className="col-md-4">
                  <div className="form-group mb-2">
                    <label>PO No.</label>
                    <input
                      className="form-control form-control-sm"
                      type="text"
                      required
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group mb-2">
                    <label>Invoice No.</label>
                    <input
                      className="form-control form-control-sm"
                      type="text"
                      required
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group mb-2">
                    <label>Date</label>
                    <input
                      className="form-control form-control-sm date-picker"
                      type="text"
                      required
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group mb-2">
                    <label>Remark</label>
                    <input
                      className="form-control form-control-sm"
                      type="text"
                    />
                  </div>
                </div>
              </div>
              <div className="table-responsive scrollbar-table mt-3 mb-3">
                <table className="data-table table hover w-100 nowrap">
                  <thead>
                    <tr>
                      <th className="text-center">#</th>
                      <th className="text-center">
                        Part Name / Color No. / Size
                      </th>
                      <th className="text-center">Part No. / Product Code</th>
                      <th className="text-center">Qty</th>
                      <th className="text-center">Remark</th>
                      <th className="datatable-nosort text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="text-center">1</td>
                      <td>Y-Connection Y adapter tubing w/Dual check valve</td>
                      <td className="text-center">1MKTGB0054</td>
                      <td className="text-center">300.00</td>
                      <td></td>
                      <td className="text-center">
                        <a
                          className="btn btn-sm btn-outline-danger"
                          href="/#"
                          // onClick="confirmDel()"
                        >
                          <i className="dw dw-trash1"></i> Delete
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="form-group mb-2">
                    <label>Part Name / Color No. / Size</label>
                    <select
                      className="custom-select2 form-control"
                      name="state"
                      style={{
                        width: "100% !important",
                        height: "38px !important",
                      }}
                      required
                    >
                      <option value="">
                        Y-Connection Y adapter tubing w/Dual check valve
                      </option>
                    </select>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group mb-2">
                    <label>Part No. / Product Code</label>
                    <input
                      className="form-control form-control-sm"
                      type="text"
                      placeholder="1MKTGB0054"
                      readOnly
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group mb-2">
                    <label>Qty</label>
                    <input
                      className="form-control form-control-sm"
                      type="text"
                      // onkeypress="return NumberFloatAndOneDOTSign(event)"
                      required
                    />
                  </div>
                </div>
                <div className="col-md-9">
                  <div className="form-group mb-2">
                    <label>Remark</label>
                    <input
                      className="form-control form-control-sm"
                      type="text"
                    />
                  </div>
                </div>
                <div className="col-md-3 d-flex align-items-center">
                  <button
                    type="button"
                    className="btn btn-sm btn-primary mt-md-4 mt-2"
                  >
                    <i className="dw dw-add mr-2"></i>ADD
                  </button>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                className="btn btn-outline-secondary btn-sm"
                data-dismiss="modal"
              >
                <i className="micon dw dw-cancel mr-2"></i>CANCEL
              </button>
              <button
                type="button"
                className="btn btn-primary btn-sm"
                //  onClick="confirmAdd()"
              >
                <i className="micon dw dw-diskette1 mr-2"></i>SAVE
              </button>
            </Modal.Footer>
          </Modal>

          {/* <!-- QRCODE Modal --> */}
          <Modal
            show={showQr}
            onHide={() => setShowQr(false)}
            dialogClassName="modal-90w"
            aria-labelledby="Qr"
          >
            <Modal.Header closeButton>
              <Modal.Title id="Qr">QR CODE</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row">
                <div className="col-md-12 d-flex justify-content-center">
                  <div id="qrcode"></div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <a
                href="/logincustomer"
                className="btn btn-outline-secondary btn-sm"
              >
                <i className="micon dw dw-user-1 mr-2"></i>LOGIN CUSTOMER
              </a>
              <button type="button" className="btn btn-primary btn-sm">
                <i className="micon dw dw-print mr-2"></i>PRINT
              </button>
            </Modal.Footer>
          </Modal>

          <div className="footer-wrap pd-20 mb-20 card-box">
            &copy; <span id="copy-year"></span> Copyright All rights reserved.
          </div>
        </div>
      </div>
    </div>
  );
}
