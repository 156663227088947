import React, { useState, useEffect } from "react";
import Menu from "./Menu.js";
import { useNavigate } from "react-router-dom";
import config from "../appsetting.json";
import Modal from "react-bootstrap/Modal";
import { Select } from "antd";
//photo
import logo from "./images/logo.svg";
import user_icon from "./images/user_icon.png";
// css
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "./styles/core.css";
import "./styles/style.css";
import "./styles/icon-font.min.css";

const { Option } = Select;
export default function ModalsDelivery(props) {
  const Swal = require("sweetalert2");
  const [historyData, setHistoryData] = useState([]);
  const [itemnames, setItemNames] = useState([]);
  const [itemoption, setItemOption] = useState([]);
  const [customername, setCustomerName] = useState([]);
  const [pono, setPoNo] = useState([]);
  const [projectcode, setProjectCode] = useState([]);
  const [date, setDate] = useState([]);
  const [plandeliverydate, setPlanDeliveryDate] = useState([]);
  const [show, setShow] = useState(false);
  const [showaddpo, setShowAddPo] = useState(false);
  const [iteminpooption, setItemInPoOption] = useState([]);
  const [iteminpooptionchange, setItemInPoOptionChange] = useState([]);
  const [order, setOrder] = useState("ASC");
  const handleClose = () => setShowAddPo(false);
  const handleShow = () => setShow(true);
  const navigate = useNavigate();
  const UNAME = localStorage.getItem("UserName");
  const POITEM = localStorage.getItem("ItemPO_No");
  const levelcode = localStorage.getItem("Level_Code");
  const [visible, setVisible] = useState(false);    

  const clicklogOut = () => {
    localStorage.clear();
    navigate("/");
  };

  const getStockList = () => {
    fetch(config.ApiPathUrl_DevServer +"/getAllPOHistory")
      .then((res) => res.json())
      .then((result) => {
        console.log(result);
        setHistoryData(result);
      });
  };

  const confirmAdd = () => {
    Swal.close();
    Swal.fire({
      icon: "success",
      title: "Success!",
      text: "You data has been added successfully.",
      // showConfirmButton: false,
      // timer: 1500
    });
  };
  const changeItemPo = (e) => {
    console.log(e);
    let d = document.getElementsByClassName("ant-select-selector");
    if (e !== "" && e !== undefined) {
      if (d[0].classList.contains("validation-select-invalid")) {
        d[0].classList.remove("validation-select-invalid");
        d[0].className += " validation-select-valid";
      }
      setItemInPoOptionChange(e);
    } else {
      if (d[0].classList.contains("validation-select-valid")) {
        d[0].classList.remove("validation-select-valid");
        d[0].className += " validation-select-invalid";
      }
      setItemInPoOptionChange("");
    }
  };
  const loadIteminpo = async () => {
    try {
      await fetch(config.ApiPathUrl_DevServer +"/checkPOItemByPONo/"+`${POITEM}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.json())
        .then((result) => {
          console.log(result);
          if (result.Status == "Success") {
            let option = [];
            result.forEach((item) => {
              option.push(
                <Option key={item.Item_Name}>{item.Item_Name}</Option>
              );
            });
            setItemInPoOption(option);
          } else {
            alert(result.Message);
          }
        });
    } catch (error) {
      console.log(error);
      alert(error);
    }
  };
  const sorting = (col) => {
    if (order === "ASC") {
      const sorted = [...historyData].sort((a, b) =>
        a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1
      );
      setHistoryData(sorted);
      setOrder("DSC");
    }
    if (order === "DSC") {
      const sorted = [...historyData].sort((a, b) =>
        a[col].toLowerCase() < b[col].toLowerCase() ? 1 : -1
      );
      setHistoryData(sorted);
      setOrder("ASC");
    }
  };
  useEffect(() => {
    getStockList();
    loadIteminpo(); 
  }, []);
  return (
    <div className="scrollbar-main sidebar-light body">
      <div className="header">
        <div className="header-left">
          <div className="menu-icon dw dw-menu pointer"></div>
        </div>
        <div className="header-right">
          <div className="user-info-dropdown">
            <div className="dropdown">
              <a
                className="dropdown-toggle"
                href="/#"
                role="button"
                data-bs-toggle="dropdown"
              >
                <span className="user-icon">
                  <img src={user_icon} alt="" />
                </span>
                <span className="user-name">{UNAME}</span>
              </a>
              <div className="dropdown-menu dropdown-menu-right dropdown-menu-icon-list">
                <a className="dropdown-item" onClick={clicklogOut}>
                  <i className="dw dw-logout"></i> Log Out
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="left-side-bar">
        <div className="brand-logo">
          <a href="/home">
            <img src={logo} alt="" className="dark-logo" width="180px" />
          </a>
          <div className="close-sidebar" data-bs-toggle="left-sidebar-close">
            <i className="ion-close-round"></i>
          </div>
        </div>
        <div className="menu-block customscroll">
          <div className="sidebar-menu icon-list-style-2">
            <ul id="accordion-menu" className="fa-ul pl-0 m-0">
              <li>
                <a href="/home" className="dropdown-toggle no-arrow">
                  <span className="micon dw dw-house1"></span>
                  <span className="mtext">Home</span>
                </a>
              </li>
              <li>
                <a href="/hospitallist" className="dropdown-toggle no-arrow">
                  <span className="micon dw dw-hospital"></span>
                  <span className="mtext">Hospital List</span>
                </a>
              </li>
              <li>
                <a href="/stockList" className="dropdown-toggle no-arrow">
                  <span className="micon dw dw-inbox1"></span>
                  <span className="mtext">Stock List</span>
                </a>
              </li>
              {visible && 
              <li>
                <a href="/pohistory" className="dropdown-toggle no-arrow">
                  <span className="micon dw dw-inbox1"></span>
                  <span className="mtext">PurchaseOrder List</span>
                </a>
              </li>}
              {visible && 
              <li className="dropdown">
                <a
                  // href="javascript:;"
                  className="dropdown-toggle"
                  data-bs-toggle="dropdown"
                >
                  <span className="micon dw dw-user1"></span>
                  <span className="mtext">User Account</span>
                </a>
                <ul className="submenu dropdown-menu">
                  <li>
                    <a href="/customer" className="dropdown-item">
                      Customer
                    </a>
                  </li>
                  <li>
                    <a href="/user" className="dropdown-item">
                      User
                    </a>
                  </li>
                  <li>
                    <a href="/level" className="dropdown-item">
                      Level
                    </a>
                  </li>
                </ul>
              </li>}
            </ul>
          </div>
        </div>
      </div>
      <div className="mobile-menu-overlay"></div>

      <div className="main-container">
        <div className="pd-ltr-20">
          <div className="card-box mb-30">
            <div className="card-body">
              <div className="clearfix">
                <div className="pull-left">
                  <h2 className="h2">Add Delivery</h2>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group mb-2">
                    <label>PO No.</label>
                    <input
                      className="form-control form-control-sm"
                      type="text"
                      readOnly
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group mb-2">
                    <label>Customer</label>
                    <input
                      className="form-control form-control-sm"
                      type="text"
                      readOnly
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group mb-2">
                    <label>Item Name </label>
                    <Select
                      style={{ width: "100%" }}
                      showSearch
                      allowClear
                      onChange={changeItemPo}
                      placeholder="Select ItemName"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    >
                      {iteminpooption}
                    </Select>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group mb-2">
                    <label>Project Code</label>
                    <input
                      className="form-control form-control-sm"
                      type="text"
                      placeholder="1MKTGB0054"
                      readOnly
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group mb-2">
                    <label>Delivery Date</label>
                    <input
                      className="form-control form-control-sm date-picker"
                      type="text"
                      required
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group mb-2">
                    <label>Balance</label>
                    <input
                      className="form-control form-control-sm date-picker"
                      type="text"
                      required
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group mb-2">
                    <label>Delivery</label>
                    <input
                      className="form-control form-control-sm"
                      type="text"
                      required
                    />
                  </div>
                </div>
                <div className="col-md-9">
                  <div className="form-group mb-2">
                    <label>Remark</label>
                    <input
                      className="form-control form-control-sm"
                      type="text"
                    />
                  </div>
                </div>
              </div>
              <div className=" text-center">
                <button
                  type="button"
                  className="btn btn-outline-secondary btn-sm m-3"
                >
                  <i className="dw dw-left-chevron mr-2"></i>BACK
                </button>
                <button
                  type="button"
                  className="btn btn-primary btn-sm m-3"
                  //  onClick="confirmAdd()"
                >
                  <i className="micon dw dw-diskette1 mr-2"></i>SAVE
                </button>
              </div>
            </div>
          </div>
          <div className="footer-wrap pd-20 mb-20 card-box">
            &copy; <span id="copy-year"></span> Copyright All rights reserved.
          </div>
        </div>
      </div>
    </div>
  );
}
