import React, { useState, useEffect } from "react"; 

export default function Menu() { 
  const [visiblePurchaseOrder, setVisiblePurchaseOrder] = useState(false);
  const [visibleuseraccount, setVisibleUserAccount] = useState(false);
  const [visiblecustomer, setVisibleCustomer] = useState(false);
  const [visiblemaster, setVisibleMaster] = useState(false);
  const [visibleDeliver, setVisibleDeliver] = useState(false);
  const levelcode = localStorage.getItem("Level_Code");

  const checklevel = () => {
    if (levelcode == 100 || levelcode == 1000) {
      setVisiblePurchaseOrder(true);
      setVisibleUserAccount(true);
      setVisibleCustomer(true);
      setVisibleMaster(true);
      //setVisibleDeliver(true);
    } else {
      setVisiblePurchaseOrder(true);
      setVisibleUserAccount(false);
      setVisibleCustomer(false);
      setVisibleMaster(false);
      //setVisibleDeliver(false);
    }
  };
 
  useEffect(() => {
    checklevel();
  }, []);

  return (
        <div className="menu-block customscroll">
          <div className="sidebar-menu icon-list-style-2">
            <ul id="accordion-menu" className="fa-ul pl-0 m-0">
              <li>
                <a href="/home" className="dropdown-toggle no-arrow">
                  <span className="micon dw dw-house1"></span>
                  <span className="mtext">Home</span>
                </a>
              </li>
              {/* <li>
                <a href="/hospitallist" className="dropdown-toggle no-arrow">
                  <span className="micon dw dw-hospital"></span>
                  <span className="mtext">Hospital List</span>
                </a>
              </li>
              <li>
                <a href="/stockList" className="dropdown-toggle no-arrow">
                  <span className="micon dw dw-inbox1"></span>
                  <span className="mtext">Stock List</span>
                </a>
              </li> */}
              {visiblePurchaseOrder && (
                <li>
                  <a href="/pohistory" className="dropdown-toggle no-arrow">
                    <span className="micon dw dw-inbox1"></span>
                    <span className="mtext">PurchaseOrder List</span>
                  </a>
                </li>
              )}
              {/* {visibleDeliver && (
                <li>
                  <a href="/Deliver" className="dropdown-toggle no-arrow">
                    <span className="micon dw dw-inbox1"></span>
                    <span className="mtext">Deliver List</span>
                  </a>
                </li>
              )} */}
              {visiblecustomer && (
                <li>
                  <a href="/customer" className="dropdown-toggle no-arrow">
                    <span className="micon dw dw-inbox1"></span>
                    <span className="mtext">Customers</span>
                  </a>
                </li>
              )}
              {visibleuseraccount && (
                <li className="dropdown">
                  <a className="dropdown-toggle " data-bs-toggle="dropdown">
                    <span className="micon dw dw-user1"></span>
                    <span className="mtext ">User Account</span>
                  </a>
                  <ul className="submenu dropdown-menu "> 
                    <li>
                      <a href="/user" className="dropdown-item">
                        User
                      </a>
                    </li>
                    <li>
                      <a href="/level" className="dropdown-item">
                        Level
                      </a>
                    </li>
                  </ul>
                </li>
              )}
              {visiblemaster && (
                <li className="dropdown">
                  <a className="dropdown-toggle " data-bs-toggle="dropdown">
                    <span className="micon dw dw-user1"></span>
                    <span className="mtext ">Master Data</span>
                  </a>
                  <ul className="submenu dropdown-menu "> 
                    <li>
                      <a href="/product" className="dropdown-item">
                        Product
                      </a>
                    </li>
                  </ul>
                </li>
              )}
            </ul>
          </div>
        </div> 
  );
}