import React, { useState, useEffect } from "react";
import Menu from './Menu.js';
import { useNavigate } from "react-router-dom";
import config from "../appsetting.json";
import Modal from "react-bootstrap/Modal";
import { Select } from "antd";
import { QRCodeSVG } from "qrcode.react";
import DatePicker from "react-datepicker";
import Swal from "sweetalert2";
//photo
import logo from "./images/logo.svg";
import user_icon from "./images/user_icon.png";
// css
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "./styles/core.css";
import "./styles/style.css";
import "./styles/icon-font.min.css";

const { Option } = Select;
export default function POHistory() {
  const [historyData, setHistoryData] = useState([]);
  const [itemNames, setItemNames] = useState([]);
  const [itemoption, setItemOption] = useState([]);
  const [itemInPOoption, setItemInPoOption] = useState([]);
  const [balance, setBalance] = useState([]);
  const [itemInPoOptionChange, setItemInPoOptionChange] = useState([]);
  const [Amount, setAmount] = useState([]);
  const [Unit, setUnit] = useState([]);
  const [deliverykeyinfo, setDeliveryKeyInfo] = useState("");
  const [poinfo, setPOInfo] = useState([]);
  const [itemcodeinfo, setItemCodeInfo] = useState([]);
  const [alinkkeyinfo, setAlinkKeyInfo] = useState([]);
  const [priceperunit, setPricePerUnit] = useState([]);
  const [total, setTotal] = useState([]);
  const [remark, setRemark] = useState([]);
  const [customername, setCustomerName] = useState([]);
  const [pono, setPoNo] = useState([]);
  const [projectcode, setProjectCode] = useState([]);
  const [date, setDate] = useState(new Date(), "dd/mm/yyyy");
  const [alinkkey, setAlinkKey] = useState([]);
  const [plandeliverydate, setPlanDeliveryDate] = useState([]);
  const [show, setShow] = useState(false);
  const [showadddelivery, setShowAddDelivery] = useState(false);
  const [showaddpo, setShowAddPo] = useState(false);
  const [showQr, setShowQr] = useState(false);
  const [totalqty, setTotalQty] = useState([]);
  const [order, setOrder] = useState("ASC");
  const handleClose = () => setShowAddPo(false);
  const navigate = useNavigate();
  const UNAME = localStorage.getItem("UserName");
  const [po, setPO] = useState([]);
  const [cusname, setCusName] = useState([]);
  const [cuscode, setCusCode] = useState([]);
  const [deliverystatus, setDeliveryStatus] = useState([]);
  const [projectcodeinmodal, setProjectCodeInModal] = useState([]);
  const [itemqtyoptionadd, setItemQtyOptionAdd] = useState([]);
  const [itemcode, setItemCode] = useState([]); 
  const [visiblePurchaseOrder, setVisiblePurchaseOrder] = useState(false);
  const [visibleuseraccount, setVisibleUserAccount] = useState(false);

  const tokenAu = localStorage.getItem("token");
  const levelcode = localStorage.getItem("Level_Code");
  const Customercode = localStorage.getItem("Cus_Code");


  //addDelivery
  const [deliveryPO, setDeliveryPO] = useState([]);
  const [deliveryPO_Detail, setDeliveryPO_Detail] = useState([]);
  const [deliveryProduct, setDeliveryProduct] = useState([]);
  const [deliveryCustomer, setDeliveryCustomer] = useState([]);
  const [deliveryTotal, setDeliveryTotal] = useState([]);
  const [delivery, setDelivery] = useState([]);
  const [deliverydate, setDeliveryDate] = useState(new Date(), "dd/mm/yyyy");
  const [deliveryBalance, setDeliveryBalance] = useState([]);


  //getall
  const [products, setProducts] = useState([]);
  const [customers, setCustomers] = useState([]);

  //qr code
  const [QRPO, setQRPO] = useState([]);
  const [QR_Customer, setQR_Customer] = useState([]);
  const [DateQR, setDateQR] = useState([]);



  const checkQTYpodetail = (idpodetail, bal) => {

    fetch(config.ApiPathUrl_DevServer +"/Deliver/GetbyPo_Detail_id/" +`${idpodetail}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          'Authorization': 'bearer '+tokenAu
        },
      }
      )
        .then((res) => res.json())
        .then((result) => {

        const deliverQtySum = result.reduce((acc, deliver) => {
          return acc + deliver.deliver_Qty;
        }, 0);

        if(bal > deliverQtySum){
          setShow(true);
        }else{
          alert("จัดส่งสินค้าตามจำนวนเรียบร้อยแล้ว");
        }
  });
  };

  const handlePrintClick = () => {
    window.print();
  };

  const clicklogOut = () => {
    localStorage.clear();
    navigate("/");
  };

  // const getAllPOHistory = () => {
  //   fetch(config.ApiPathUrl_DevServer +"/getAllPOHistory")
  //     .then((res) => res.json())
  //     .then((result) => {
  //       setHistoryData(result);
  //     });
  // };

  const getAllPOHistory = () => {
    if (levelcode == 100 || levelcode == 1000){

      fetch(config.ApiPathUrl_DevServer +"/Po",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          'Authorization': 'bearer '+tokenAu
        },
      }
      )
        .then((res) => res.json())
        .then((result) => {
          //console.log(result);
          setHistoryData(result);
        });
    }else{
      fetch(config.ApiPathUrl_DevServer +"/Po/Customer/"+ `${Customercode}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          'Authorization': 'bearer '+tokenAu
        },
      }
      )
        .then((res) => res.json())
        .then((result) => {
          //console.log(result);
          setHistoryData(result);
        });
    }
    
  };

  const GetAllProduct = () =>{
    fetch(config.ApiPathUrl_DevServer +"/Product",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          'Authorization': 'bearer '+tokenAu
        },
      }
      )
        .then((res) => res.json())
        .then((result) => {
          //console.log(result);
          setProducts(result);
        });
  };

  const GetAllCustomer = () =>{
    fetch(config.ApiPathUrl_DevServer +"/Customer",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          'Authorization': 'bearer '+tokenAu
        },
      }
      )
        .then((res) => res.json())
        .then((result) => {
          //console.log(result);
          setCustomers(result);
        });

  };

  const confirmAdd = () => {
    Swal.close();
    Swal.fire({
      icon: "success",
      title: "Success!",
      text: "You data has been added successfully.",
    }).then(() => {
      window.location.reload();
    });
  };
  
  const changeItem = (e) => {
    console.log(e);
    let d = document.getElementsByClassName("ant-select-selector");
    if (e !== "" && e !== undefined) {
      if (d[0].classList.contains("validation-select-invalid")) {
        d[0].classList.remove("validation-select-invalid");
        d[0].className += " validation-select-valid";
      }
      setItemNames(e);
    } else {
      if (d[0].classList.contains("validation-select-valid")) {
        d[0].classList.remove("validation-select-valid");
        d[0].className += " validation-select-invalid";
      }
      setItemNames("");
    }
  };
  const changeItemPo = (e) => {
    console.log(e);
    let d = document.getElementsByClassName("ant-select-selector");
    if (e !== "" && e !== undefined) {
      if (d[0].classList.contains("validation-select-invalid")) {
        d[0].classList.remove("validation-select-invalid");
        d[0].className += " validation-select-valid";
      }
      setItemInPoOptionChange(e);
      getQty(e);
      console.log(e);
      GetInfoDelivery(po, e);
    } else {
      if (d[0].classList.contains("validation-select-valid")) {
        d[0].classList.remove("validation-select-valid");
        d[0].className += " validation-select-invalid";
      }
      setItemInPoOptionChange("");
      GetInfoDelivery(po, e);
    }
  };

  const loadgetAllUserLevels = async () => {
    try {
      await fetch(config.ApiPathUrl_DevServer + "/getAllUserLevels", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.json())
        .then((result) => {
          if (result.Status == "Success") {
            let option = [];
            result.Data.forEach((item) => {
              option.push(
                <Option key={item.Level_Code}>{item.Level_Name}</Option>
              );
            });
            setItemOption(option);
          } else {
            alert(result.Message);
          }
        });
    } catch (error) {
      console.log(error);
      alert(error);
    }
  };

  const loadIteminpo = async (POITEM) => {
    try {
      await fetch(config.ApiPathUrl_DevServer +"/Po/Po_no/" + `${POITEM}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          'Authorization': 'bearer '+tokenAu
        },
      })
        .then((res) => res.json())
        .then((result) => {
          console.log(result);
          setItemInPoOption(result);
          setItemCode(result.ItemCode);
        });
    } catch (error) {
      console.log(error);
      alert(error);
    }
  };
  const clicktopo = () => {
    navigate("/addpo");
  };

  const GetInfoDelivery = (DeliveryPO, ITEMCODE) => {
    fetch(config.ApiPathUrl_DevServer +"/checkDeliveryByPONo/" +`${DeliveryPO}` + "/" + `${ITEMCODE}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((result) => {
        setAlinkKeyInfo(result[0].Alink_Key);
        setDeliveryKeyInfo(result[0].Delivery_Key);
        setPOInfo(result[0].PO_No);
        setItemCodeInfo(result[0].ItemCode);
      });
  };

  const onclickConfirmadd = () => {
    AddDeliveryINFO();
    editQtyfordelivery();
    confirmAdd();
    setShowAddDelivery(false);
  };

  const checkBeforeShowAddDelivery = () =>{
    if(deliveryBalance >= deliveryTotal){
      setShowAddDelivery(true);
    }else{
      alert("จำนวนเยอะเกินไป");
    }
  };

  const AddDeliveryINFO = () => {
    try {
      fetch(config.ApiPathUrl_DevServer + "/Deliver", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          'Authorization': 'bearer '+tokenAu
        },
        body: JSON.stringify({
          id: 0,
          po_Detail_Id: deliveryPO_Detail,
          product_Id: deliveryProduct,
          deliver_Qty: deliveryTotal,
          deliver_dttm: deliverydate,
          received_dttm: deliverydate,
          received_Qty: 0,
          received_By: 0,
          remark: "",

        }),
      })
      .then(response => {
        console.log(response)
        if(response.status == 200){
          confirmAdd();
        }
      })
      .then(data => {
        console.log(data);
      })
    } catch (error) {
      console.log(error);
      alert(error);
    }
  };
  const getTotalQty = () => {
    fetch(config.ApiPathUrl_DevServer +"/checkTotalsQtyInPO")
      .then((res) => res.json())
      .then((result) => {
        console.log(result);
        setTotalQty(result[0].AllQty);
      });
  };
  const getQty = (Code) => {
    fetch(config.ApiPathUrl_DevServer +"/checkQtyInItemCode/" + `${Code}`)
      .then((res) => res.json())
      .then((result) => {
        setBalance(result[0].Qty);
      });
  };
  const editQtyfordelivery = async () => {
    try {
      await fetch(
        config.ApiPathUrl_DevServer + "/updateItemfordeliveryByCode",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ItemCode: itemInPoOptionChange,
            Qty: itemqtyoptionadd,
          }),
        }
      )
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          if (data.Status == "Success") {
            confirmEdit();
          } else {
            alert(data.Message);
          }
          // setLoading(false);
        });
    } catch (error) {
      console.log(error);
      // setLoading(false);
    }
  };

  const confirmEdit = () => {
    Swal.close();
    Swal.fire({
      icon: "success",
      title: "Success!",
      text: "You data has been saved successfully.",
    });
  };

  const setLocalStorage = (po, po_detail) => {
    localStorage.setItem("PO_No", po);
    localStorage.setItem("product_detail_id", po_detail);
  };

  const setShowQrDeliver = (po,cus,pro,date) => {
    setShowQr(true);
    setQRPO(po);
    setQR_Customer(cus);
    setDateQR(date);
  };

  useEffect(() => {
    getAllPOHistory();
    GetAllProduct();
    GetAllCustomer();
    //loadgetAllUserLevels(); 
    //loadIteminpo();
    //getTotalQty();
  }, []);
  return (
    <div className="scrollbar-main sidebar-light body">
      <div className="header">
        <div className="header-left">
          <div className="menu-icon dw dw-menu pointer"></div>
        </div>
        <div className="header-right">
          <div className="user-info-dropdown">
            <div className="dropdown">
              <a
                className="dropdown-toggle"
                href="/#"
                role="button"
                data-bs-toggle="dropdown"
              >
                <span className="user-icon">
                  <img src={user_icon} width={"100%"} alt="" />
                </span>
                <span className="user-name">{UNAME}</span>
              </a>
              <div className="dropdown-menu dropdown-menu-right dropdown-menu-icon-list">
                <a className="dropdown-item" onClick={clicklogOut}>
                  <i className="dw dw-logout"></i> Log Out
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="left-side-bar">
        <div className="brand-logo">
          <a href="/home">
            <img src={logo} alt="" className="dark-logo" width="180px" />
          </a>
          <div className="close-sidebar" data-bs-toggle="left-sidebar-close">
            <i className="ion-close-round"></i>
          </div>
        </div>
        <Menu/>
      </div>
      <div className="mobile-menu-overlay"></div>

      <div className="main-container">
        <div className="pd-ltr-20">
          <div className="card-box mb-30">
            <div className="card-body">
              <div className="clearfix">
                <div className="pull-left">
                  <h2 className="h2">PO History</h2>
                </div>
                {levelcode == "1000" || levelcode == "100" ? (
                <div className="pull-right">
                  {/* <a
                    className="btn btn-sm btn-outline-dark mr-2"
                    href="/stockList"
                  >
                    <i className="dw dw-left-chevron mr-2"></i>BACK
                  </a> */}
                  <a className="btn btn-sm btn-primary" onClick={clicktopo}>
                    <i className="dw dw-truck mr-2"></i>Add PO
                  </a>
                </div>
                ) : null }
              </div>

              <div className="table-responsive scrollbar-table">
                <table className="data-table table hover w-100 nowrap">
                  <thead>
                    <tr>
                      <th
                        className="text-center"
                        // onClick={() => sorting("ItemCode")}
                      >
                        #
                      </th>
                      <th
                        className="text-center"
                        // onClick={() => sorting("PO_No")}
                      >
                        PO No.
                      </th>
                      <th
                        className="text-center"
                        // onClick={() => sorting("Item_Name")}
                      >
                        Item Name
                      </th>
                      <th
                        className="text-center"
                        // onClick={() => sorting("Item_Name")}
                      >
                        Qty
                      </th>
                      <th
                        className="text-center"
                        // onClick={() => sorting("Item_Name")}
                      >
                        Balance
                      </th>
                      <th
                        className="text-center"
                        // onClick={() => sorting("Cus_Name")}
                      >
                        Customer Name
                      </th>
                      <th
                        className="text-center"
                        // onClick={() => sorting("DateInPo")}
                      >
                        Date
                      </th>
                      <th
                        className="text-center"
                        // onClick={() => sorting("DateInPo")}
                      >
                        Status
                      </th>
                      <th
                        className="text-center"
                        // onClick={() => sorting("Remark")}
                      >
                        Remark
                      </th>
                      <th className="datatable-nosort text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                  {historyData.map((item, i) => (
                    <React.Fragment key={i}>
                      {item.po_Detail.map((detailItem, j) => (
                        <tr key={j}>
                          <td className="text-center">{item.id}</td>
                          <td className="text-center">{item.po_no}</td>
                          <td className="text-center">
                            {products.find((p) => p.id === detailItem.product_id)?.productName}
                          </td>
                          <td className="text-center">{detailItem.qty}</td>
                          <td className="text-center">{detailItem.balance}</td>
                          <td className="text-center">
                            {customers.find((p) => p.id === item.customer_id)?.name}
                          </td>
                          <td className="text-center">{new Date(item.po_dttm).toLocaleDateString('en-GB')}</td>
                          <td className="text-center">{item.status}</td>
                          <td className="text-center">{detailItem.remark}</td>
                          <td className="text-center">
                            <div className="dropdown">
                              <a
                                className="btn btn-link font-24 p-0 line-height-1 no-arrow dropdown-toggle"
                                href="/#"
                                role="button"
                                data-bs-toggle="dropdown"
                              >
                                <i className="dw dw-more"></i>
                              </a>
                              <div className="dropdown-menu dropdown-menu-right dropdown-menu-icon-list">

                              {levelcode == "1000" || levelcode == "100" && item.status === "open"? (
                                <a
                                  className="dropdown-item"
                                  // href="/adddelivery"
                                  onClick={() => (
                                    checkQTYpodetail(detailItem.id, detailItem.balance),
                                    setDeliveryPO(item.po_no),
                                    setDeliveryProduct(detailItem.product_id),
                                    setDeliveryCustomer(item.customer_id),
                                    setDeliveryPO_Detail(detailItem.id),
                                    localStorage.setItem("ItemPO_No", item.po_no),
                                    setDeliveryBalance(detailItem.balance)
                                  )}
                                >
                                  <i className="dw dw-truck mr-2"></i>Add Delivery
                                </a>
                                ) : null}

                                <a
                                  className="dropdown-item"
                                  //href="/delivery"
                                  href="/deliverlist"
                                  onClick={ () => setLocalStorage(item.po_no, detailItem.id)}
                                >
                                  <i className="dw dw-truck"></i> Delivery Notes
                                </a>

                                {levelcode == "1000" || levelcode == "100" && item.status === "open" ? (
                                <a
                                  className="dropdown-item"
                                  onClick={() => setShowQrDeliver(item.po_no, item.customer_id, detailItem.product_Id, item.po_dttm)}
                                >
                                  <i className="dw dw-print"></i> Print QR
                                </a>
                                ) : null}
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </React.Fragment>
                  ))}
                </tbody>

                </table>
              </div>
            </div>
          </div>

          {/* <!-- Add PO Modal --> */}
          <Modal
            show={showaddpo}
            onHide={() => setShowAddPo(false)}
            dialogClassName="modal-90w"
            aria-labelledby="AddPo"
          >
            <Modal.Header closeButton>
              <Modal.Title id="AddPo">Add Purcahse Order</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group mb-2">
                    <label>CustomerName</label>
                    <input
                      className="form-control form-control-sm"
                      type="text"
                      required
                      onChange={(e) => setCustomerName(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group mb-2">
                    <label>PO No.</label>
                    <input
                      className="form-control form-control-sm"
                      type="text"
                      required
                      onChange={(e) => setPoNo(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group mb-2">
                    <label>ProjectCode</label>
                    <input
                      className="form-control form-control-sm"
                      type="text"
                      required
                      onChange={(e) => setProjectCode(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group mb-2">
                    <label>Date</label>
                    <DatePicker
                      className="form-control form-control-sm"
                      dateFormat="dd/MM/yyyy"
                      selected={date}
                      onChange={(date) => setDate(date)}
                    />
                    {/* <input
                      className="form-control form-control-sm date-picker"
                      type="text"
                      required
                      onChange={(e) => setDate(e.target.value)}
                    /> */}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group mb-2">
                    <label>กำหนดส่ง(จำนวนวัน)</label>
                    <input
                      className="form-control form-control-sm date-picker"
                      type="text"
                      required
                      onChange={(e) => setPlanDeliveryDate(e.target.value)}
                    />
                  </div>
                </div>
                <div className="card-body">
                  <div className="col-md-12">
                    <div className="form-group mb-2">
                      <label>ItemName</label>
                      <Select
                        style={{ width: "100%" }}
                        showSearch
                        allowClear
                        placeholder="Select a Item"
                        optionFilterProp="children"
                        onChange={changeItem}
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                      >
                        {itemoption}
                      </Select>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group mb-2">
                      <label>Amount</label>
                      <input
                        className="form-control form-control-sm"
                        type="text"
                        onChange={(e) => setAmount(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group mb-2">
                      <label>Unit</label>
                      <input
                        className="form-control form-control-sm"
                        type="text"
                        onChange={(e) => setUnit(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group mb-2">
                      <label>PricePerUnit</label>
                      <input
                        className="form-control form-control-sm"
                        type="text"
                        onChange={(e) => setPricePerUnit(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group mb-2">
                      <label>Total</label>
                      <input
                        className="form-control form-control-sm"
                        type="text"
                        onChange={(e) => setTotal(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group mb-2">
                      <label>Remark</label>
                      <input
                        className="form-control form-control-sm"
                        type="text"
                        onChange={(e) => setRemark(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-3 d-flex align-items-center">
                  <button
                    type="button"
                    className="btn btn-sm btn-primary mt-md-4 mt-2"
                  >
                    <i className="dw dw-add mr-2"></i>ADD
                  </button>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                className="btn btn-outline-secondary btn-sm"
                data-dismiss="modal"
                onClick={handleClose}
              >
                <i className="micon dw dw-cancel mr-2"></i>CANCEL
              </button>
              <button type="button" className="btn btn-primary btn-sm">
                <i className="micon dw dw-diskette1 mr-2"></i>SAVE
              </button>
            </Modal.Footer>
          </Modal>
          {/* <!-- Add Modal --> */}
          <Modal
            show={show}
            onHide={() => setShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="AddDelivery"
          >
            <Modal.Header closeButton>
              <Modal.Title id="AddDelivery">Add Delivery</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group mb-2">
                    <label>PO No.</label>
                    <input
                      className="form-control form-control-sm"
                      type="text"
                      value={deliveryPO}
                      readOnly
                    />
                  </div>
                </div>
                {/* <div className="col-md-6">
                  <div className="form-group mb-2">
                    <label>Project Code</label>
                    <input
                      className="form-control form-control-sm"
                      type="text"
                      value={projectcodeinmodal}
                      readOnly
                    />
                  </div>
                </div> */}
                <div className="col-md-12">
                  <div className="form-group mb-2">
                    <label>Customer</label>
                    <input
                      className="form-control form-control-sm"
                      type="text"
                      value={customers.find((p) => p.id === deliveryCustomer)?.name}
                      readOnly
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <div className="form-group mb-2">
                    <label>Item Name </label>
                    <input
                      className="form-control form-control-sm"
                      type="text"
                      value={products.find((p) => p.id === deliveryProduct)?.productName}
                      readOnly
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group mb-2">
                    <label>Balance</label>
                    <input
                      className="form-control form-control-sm date-picker"
                      type="text"
                      value={deliveryBalance}
                      readOnly
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group mb-2">
                    <label>Delivery Date</label>
                    <DatePicker
                      className="form-control form-control-sm"
                      dateFormat="dd/MM/yyyy"
                      selected={deliverydate}
                      onChange={(date) => setDeliveryDate(date)}
                    />
                    {/* <input
                      className="form-control form-control-sm date-picker"
                      type="text"
                      onChange={(e) => setDeliveryDate(e.target.value)}
                      required
                    /> */}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group mb-2">
                    <label>Delivery</label>
                    <input
                      className="form-control form-control-sm"
                      type="text"
                      onChange={(e) => setDeliveryTotal(e.target.value)}
                      required
                    />
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                className="btn btn-primary btn-sm"
                // onClick={checkBeforeAddDelivery}
                onClick={() => checkBeforeShowAddDelivery()}
              >
                <i className="micon dw dw-diskette1 mr-2"></i>SAVE
              </button>
            </Modal.Footer>
          </Modal>

          {/* <!-- QRCODE Modal --> */}
          <Modal
            show={showQr}
            onHide={() => setShowQr(false)}
            dialogClassName="modal-90w"
            aria-labelledby="Qr"
          >
            <Modal.Header closeButton>
              <Modal.Title id="Qr">QR CODE</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row">
                <div className="col-md-12 d-flex justify-content-center">
                  <div id="qrcode">
                    <QRCodeSVG value={config.ApiQRcode +`/logincustomer?pono_id=${QRPO}`} />,
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group mb-2">
                    <label>PO No.</label>
                    <input
                      className="form-control form-control-sm"
                      type="text"
                      value={QRPO}
                      readOnly
                    />
                  </div>
                </div>
                {/* <div className="col-md-6">
                  <div className="form-group mb-2">
                    <label>Project Code</label>
                    <input
                      className="form-control form-control-sm"
                      type="text"
                      value={projectcodeinmodal}
                      readOnly
                    />
                  </div>
                </div> */}
                <div className="col-md-12">
                  <div className="form-group mb-2">
                    <label>Customer Name</label>
                    <input
                      className="form-control form-control-sm"
                      type="text"
                      value={customers.find((p) => p.id === QR_Customer)?.name}
                      readOnly
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group mb-2">
                    <label>Date</label>
                    <input
                      className="form-control form-control-sm"
                      type="text"
                      value={new Date(DateQR).toLocaleDateString('en-GB')}
                      readOnly
                    />
                  </div>
                </div>
              </div>
              
            </Modal.Body>
            <Modal.Footer>
              <button 
              type="button" 
              className="btn btn-primary btn-sm"
              onClick={handlePrintClick}
              >
                <i className="micon dw dw-print mr-2">
                  </i>PRINT
              </button>
            </Modal.Footer>
          </Modal>
          {/* <!-- ยืนยัน Modal --> */}
          <Modal
            show={showadddelivery}
            onHide={() => setShowAddDelivery(false)}
            dialogClassName="modal-50w"
            aria-labelledby="AD"
          >
            <Modal.Header closeButton>
              <Modal.Title id="AD">ยืนยันข้อมูล</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row">
                <div className="col-md-12 text-center">
                  <h5>ต้องการยืนยันข้อมูลใช่หรือไม่</h5>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div className="col-lg-12 text-center">
                <button
                  type="button"
                  className="btn btn-primary btn-sm mx-2"
                  // onClick={onclickConfirmadd}
                  onClick={AddDeliveryINFO}
                >
                  <i className="micon dw dw-diskette1 mr-2"></i>SAVE
                </button>
              </div>
            </Modal.Footer>
          </Modal>
          <div className="footer-wrap pd-20 mb-20 card-box">
            &copy; <span id="copy-year"></span> Copyright All rights reserved.
          </div>
        </div>
      </div>
    </div>
  );
}
